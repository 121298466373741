import React, { useState, useEffect } from 'react';
import { LoadingWrapper, AgilityTypography } from 'Src/AgilityComponents';
import SignUpSuccess from './SignUpSuccess';
import SignUpFailure from './SignUpFailure';
import i18n from 'i18next';
import { object } from 'prop-types';
import { APPLICATION_ID, ACCOUNT_ID } from 'App/utils/constants';
import { Helmet } from 'react-helmet';
import { withSnackbar } from 'notistack';
import { getReferFromCookie } from 'App/utils/helper';
import cookie from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { showDebugInfoBar } from 'App/customConfig';

const SignUpCompletion = props => {
  const [state, setState] = useState({
    loading: false,
    onSuccess: false,
    signUpSubmitResponse: {},
    onError: false,
    customerName: '',
  });

  const history = useHistory();

  const cleanStore = () => {
    // Don't clear local storage for debugging purposes
    if (!showDebugInfoBar) {
      localStorage.clear();
      props.resetOnBoardingUserData();
      props.resetComparePlanUserData();
    }
  };

  const getApplicationID = () => {
    return localStorage.getItem(APPLICATION_ID);
  };

  useEffect(() => {
    window.onbeforeunload = () => undefined;
    const appId = getApplicationID();
    if (appId) {
      setState(prevState => ({ ...prevState, loading: true }));
      callGetSignupDetails(appId);
    } else {
      redirectHome();
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const redirectHome = () => {
    history.push('/');
  };

  const userSignUpSubmit = data => {
    props
      .submitSignupDetails(data)
      .then(response => {
        if (response.id) {
          setState(prevState => ({
            ...prevState,
            signUpSubmitResponse: response,
            loading: false,
            onSuccess: true,
          }));
        } else {
          setState(prevState => ({
            ...prevState,
            loading: false,
            onError: true,
          }));
        }
      })
      .catch(error => {
        if (error?.message) {
          props.enqueueSnackbar(error.message, {
            variant: 'error',
          });
        }
        setState(prevState => ({
          ...prevState,
          loading: false,
          onError: true,
        }));
      });
  };

  const callGetSignupDetails = appId => {
    props
      .getSignupDetails(appId, localStorage.getItem(ACCOUNT_ID))
      .then(response => {
        cleanStore();
        const [primaryContact] = response.customers;
        if (primaryContact) {
          setState(prevState => ({
            ...prevState,
            customerName: primaryContact['first_name'],
          }));
        }
        userSignUpSubmit(response);
      })
      .catch(error => {
        cleanStore();
        props.enqueueSnackbar(i18n.t('signup.error.message'), {
          variant: 'error',
        });
        setState(prevState => ({
          ...prevState,
          loading: false,
          onError: true,
        }));
      });
  };

  const findValueByName = (arr, name) => {
    const data = arr.find(x => x.name === name);
    return data ? data.value : '';
  };

  const getServices = summaryDetails => {
    return summaryDetails.filter(obj => obj.name === 'Service');
  };

  const getRefer = () => {
    const refer = getReferFromCookie();
    if (refer !== null) {
      cookie.remove('refer');
    }
    return refer;
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Signup Success! | ${process.env.REACT_APP_RETAILER_NAME}`}</title>
      </Helmet>
      <LoadingWrapper
        className="full-page-loader"
        isLoading={state.loading}
        loadingMessage={
          <React.Fragment>
            <AgilityTypography
              variant="h3"
              component="h3"
              align="center"
              className="mb-2"
            >
              {i18n.t('signUpCompletion.loading.text')}
            </AgilityTypography>
          </React.Fragment>
        }
      />
      {state.onSuccess && state.signUpSubmitResponse && (
        <SignUpSuccess
          data-test-id="sign-up-success"
          address={state.signUpSubmitResponse.address}
          referenceNo={findValueByName(
            state.signUpSubmitResponse.summaryDetails,
            'Reference No.'
          )}
          connectionDate={findValueByName(
            state.signUpSubmitResponse.summaryDetails,
            'Connection Date'
          )}
          service={getServices(state.signUpSubmitResponse.summaryDetails)}
          paymentOption={findValueByName(
            state.signUpSubmitResponse.summaryDetails,
            'Payment option'
          )}
          lifeSupport={findValueByName(
            state.signUpSubmitResponse.summaryDetails,
            'Life Support'
          )}
          promoCode={findValueByName(
            state.signUpSubmitResponse.summaryDetails,
            'Promo code'
          )}
          refer={getRefer()}
          showReferAFriend={!state.signUpSubmitResponse.isAgentSale}
        />
      )}
      {state.onError && (
        <SignUpFailure
          data-test-id="sign-up-failure"
          customerName={state.customerName}
        />
      )}
    </React.Fragment>
  );
};

SignUpCompletion.propTypes = {
  signupAPIResponse: object,
};
SignUpCompletion.defaultProps = {
  signupAPIResponse: {},
};

export { SignUpCompletion };

export default withSnackbar(SignUpCompletion);
