import React from 'react';
import BasicPriceDetailsWrapper from 'App/pages/ComparePlans/Components/ProductPlans/BasicPriceDetailsWrapper';
import {
  AgilityButton,
  AgilityCard,
  AgilityTypography,
} from 'Src/AgilityComponents';
import i18n from 'i18next';
import { SIGNUP_STEPS as signupSteps } from 'App/utils/constants';
import { showPlanImage } from 'App/customConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class StepOneCard extends React.Component {
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.isActive &&
      prevProps.saveProgressTrigger !== this.props.saveProgressTrigger
    ) {
      const data = { ...this.props.signupAPIResponse };
      data.currentPage = signupSteps.selectedPlan;
      this.props.onNavigate('progress', data, true);
    }
  }

  onNavigate = () => {
    const data = { ...this.props.signupAPIResponse };
    data.currentPage =
      data.propertyType === 'COMPANY'
        ? signupSteps.yourCompanyDetails
        : signupSteps.yourDetails;
    this.props.onNavigate('next', data, false);
  };

  render() {
    return (
      <AgilityCard
        className={`steps-wrapper ${this.props.className}`}
        id={this.props.id}
      >
        <BasicPriceDetailsWrapper
          signupAPIResponse={this.props.signupAPIResponse}
          site={this.props.signupAPIResponse.address}
          productList={this.props.productList}
          selectedPlans={this.props.selectedPlans}
          displayProductHeading
          showImage={showPlanImage}
          displayProduct={
            process.env.REACT_APP_BRAND_CODE === 'SEG' ? false : true
          }
          isEmbeddedNetwork={this.props.isEmbeddedNetwork}
        />
        <AgilityTypography variant="h5" component="h5" align="center">
          {Object.keys(this.props.selectedPlans).length > 1
            ? i18n.t('stepper.yourplan.stepprompt2')
            : i18n.t('stepper.yourplan.stepprompt')}
        </AgilityTypography>
        <div className="steps-footer">
          {this.props.isAgentView && (
            <AgilityButton
              color="primary"
              onClick={this.props.openProgressDialog}
              label={i18n.t('saveprogress.sms.button')}
              data-test-id="smsButton"
              endIcon={<FontAwesomeIcon icon={['fas', 'envelope']} />}
            />
          )}
          <AgilityButton
            color="primary"
            onClick={() => {
              this.props.history.push(`/`);
            }}
            disabled={!this.props.isActive}
            label={i18n.t('signup.button.back')}
            className="push"
          />
          <AgilityButton
            variant="contained"
            color="primary"
            type="primary"
            label={i18n.t('signup.button.next')}
            to="YourDetail"
            spy={true}
            smooth={true}
            duration={250}
            disabled={!this.props.isActive}
            containerId="containerElement"
            onClick={() => {
              this.onNavigate();
            }}
            loading={this.props.nextLoading}
          />
        </div>
      </AgilityCard>
    );
  }
}

export default StepOneCard;
