/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React from 'react';
import StepOneCard from 'App/modules/onBoarding';
import YourDetail from 'App/modules/onBoarding/step2/YourDetail';
import MobileAuthentication from 'App/modules/onBoarding/step2/MobileAuthentication';
import CompanyDetail from 'App/modules/onBoarding/step2/CompanyDetail';
import YourProperty from 'App/modules/onBoarding/step2/YourProperty';
import Identification from 'App/modules/onBoarding/step2/Identification';
import { AgilityTypography } from 'Src/AgilityComponents';
import PaymentMethod from 'App/modules/onBoarding/step3/PaymentMethod';
import PaymentDetails from 'App/modules/onBoarding/step3/PaymentDetails';
import i18n from 'i18next';
import './wizard.scss';
import { Element, scroller } from 'react-scroll';
import CompleteSummary from 'App/modules/onBoarding/summary';
import { SIGNUP_STEPS as signupSteps } from 'App/utils/constants';
import {
  showDirectDebitStep,
  showBillingStep,
  hasMobileAuthentication,
} from 'App/customConfig';
import LifeSupportConcession from 'App/modules/onBoarding/step4/LifeSupport';
import { withSnackbar } from 'notistack';
import SaveProgressDialog from 'App/modules/onBoarding/saveProgress';
import { isEmpty } from '../../utils/helper';
import cookie from 'js-cookie';
import { logSaleAction } from 'App/utils/geolocation';
import { APPLICATION_ID, SIGNUP_BY_SALE } from 'App/utils/constants';

class Wizard extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      visibleSteps: [],
      showProgressDialog: false,
      saveProgressTargetIsEmail: false,
      isAgentView: false,
      isSignupBySale: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this._isMounted) {
      if (this.props.currentStep.id !== prevProps.currentStep.id) {
        this.props.history.push(`/user-signup/${this.props.currentStep.id}`);
      }

      if (this.props.match.params.step !== prevProps.match.params.step) {
        let alterOffset = false;
        if (
          this.props.match.params.step === signupSteps.paymentDetails &&
          prevProps.match.params.step === signupSteps.completeSummary
        ) {
          alterOffset = true;
        }
        this.scrollToStep(alterOffset);
      }
    }
  }

  scrollToStep = (alterOffset = false) => {
    let delay = 0;
    if (alterOffset) {
      delay = 300;
    }
    setTimeout(() => {
      scroller.scrollTo(this.props.match.params.step, {
        duration: 1000,
        delay: 10,
        smooth: 'easeInOutQuart',
        isDynamic: true,
        top: 0,
        offset: -220, // Scrolls to element + 220 pixels down the page
      });
    }, delay);
  };

  onSaveProgress = () => {
    this.setState({
      showProgressDialog: false,
    });
  };

  onDismiss = () => {
    this.setState({
      showProgressDialog: false,
    });
  };

  openProgressDialog = () => {
    // this.props.triggerEmailSetter(this.state.saveProgressTargetIsEmail);
    this.setState({
      showProgressDialog: true,
    });
  };

  componentDidMount() {
    this._isMounted = true;
    if (this.props.match.params.step === signupSteps.selectedPlan) {
      this.scrollToStep(false);
    }
    window.addEventListener('load', () => {
      this.scrollToStep(false);
    });

    const { agentEnteredCode } = cookie.get();
    if (!isEmpty(agentEnteredCode)) {
      this.setState({
        isAgentView: true,
      });
    }
    this.setState({
      isSignupBySale:
        localStorage.getItem(SIGNUP_BY_SALE) ===
        localStorage.getItem(APPLICATION_ID),
    });

    Promise.all([
      this.props.fetchStateList(),
      // this.props.fetchSolarTypeList(),
      this.props.fetchIdentificationTypeList(),
      ...(this.props.customConfig.showMeterQuestion
        ? [this.props.fetchCoordinatorsList()]
        : []),
      this.props.fetchSalutationList(),
      this.props.fetchPreferHearingList(),
      this.props.fetchProductList(),
    ])
      .then(() => {
        this.scrollToStep(false);
      })
      .catch(error => {
        if (error?.message) {
          this.props.enqueueSnackbar(error.message, {
            variant: 'error',
          });
        }
      });

    const api_code = cookie.get('agentEnteredCode');
    if (api_code !== undefined) {
      this.props.validateAgentLogin({ api_code });
      logSaleAction({ api_code }, 'AGENT_AUTH_CHECK_AT_SIGNUP_WIZARD');
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('load', this.scrollToStep);
    this.props.resetCurrentStep();
  }

  render() {
    const isCompany = this.props.selectedProperty === 'COMPANY';
    return (
      <div
        className={
          'step-scroller ' +
          (process.env.REACT_APP_BRAND_CODE === 'FIRST' ? 'mt-5' : '')
        }
        id="stepScroller"
      >
        <ul>
          {this.props.match.params.step !== signupSteps.completeSummary && (
            <li className="steps-content">
              <div
                className={`steps-header ${
                  this.props.match.params.step === signupSteps.selectedPlan
                    ? 'active'
                    : ''
                }`}
              >
                <AgilityTypography component="h2" variant="h2">
                  {i18n.t('onboarding.selectedplan.title')}
                </AgilityTypography>
                <AgilityTypography
                  component="p"
                  variant="body2"
                  color="textPrimary"
                >
                  {i18n.t('onboarding.selectedplan.description')}
                </AgilityTypography>
              </div>
              <Element name="selected-plan">
                <StepOneCard
                  {...this.props}
                  selectedPlans={this.props.selectedPlans}
                  onNavigate={this.props.onNavigate}
                  name="StepOneCard"
                  id="selected-plan"
                  className={
                    this.props.match.params.step === signupSteps.selectedPlan
                      ? 'active'
                      : ''
                  }
                  isActive={
                    this.props.match.params.step === signupSteps.selectedPlan
                  }
                  nextLoading={
                    this.props.nextLoading &&
                    this.props.match.params.step === signupSteps.selectedPlan
                  }
                  signupAPIResponse={this.props.signupAPIResponse}
                  saveProgressTrigger={this.props.saveProgressTrigger}
                  openProgressDialog={this.openProgressDialog}
                  isAgentView={this.state.isAgentView}
                />
              </Element>
            </li>
          )}
          {this.props.match.params.step !== signupSteps.completeSummary && (
            <li className="steps-content">
              <div
                className={`steps-header ${
                  isCompany
                    ? this.props.match.params.step ===
                      signupSteps.yourCompanyDetails
                      ? 'active'
                      : ''
                    : this.props.match.params.step === signupSteps.yourDetails
                    ? 'active'
                    : ''
                }`}
              >
                <AgilityTypography component="h2" variant="h2">
                  {i18n.t('onboarding.aboutyou.title')}
                </AgilityTypography>
                <AgilityTypography
                  component="p"
                  variant="body2"
                  color="textPrimary"
                >
                  {i18n.t(
                    isCompany
                      ? 'onboarding.yourcompany.description'
                      : 'onboarding.aboutyou.description'
                  )}
                </AgilityTypography>
              </div>
              {isCompany && (
                <Element name="your-company-details">
                  <CompanyDetail
                    onNavigate={this.props.onNavigate}
                    name="CompanyDetail"
                    id="your-company-details"
                    className={`child-elem ${
                      this.props.match.params.step ===
                      signupSteps.yourCompanyDetails
                        ? 'active'
                        : ''
                    }`}
                    isActive={
                      this.props.match.params.step ===
                      signupSteps.yourCompanyDetails
                    }
                    nextLoading={
                      this.props.nextLoading &&
                      this.props.match.params.step ===
                        signupSteps.yourCompanyDetails
                    }
                    signupAPIResponse={this.props.signupAPIResponse}
                    saveProgressTrigger={this.props.saveProgressTrigger}
                  />
                </Element>
              )}
              <Element name="your-details">
                <YourDetail
                  onNavigate={this.props.onNavigate}
                  name="YourDetail"
                  propertyType={this.props.selectedProperty}
                  id="your-details"
                  className={`child-elem ${
                    this.props.match.params.step === signupSteps.yourDetails
                      ? 'active'
                      : ''
                  }`}
                  isActive={
                    this.props.match.params.step === signupSteps.yourDetails
                  }
                  nextLoading={
                    this.props.nextLoading &&
                    this.props.match.params.step === signupSteps.yourDetails
                  }
                  signupAPIResponse={this.props.signupAPIResponse}
                  saveProgressTrigger={this.props.saveProgressTrigger}
                  openProgressDialog={this.openProgressDialog}
                  isAgentView={this.state.isAgentView}
                />
              </Element>
              {!(this.state.isAgentView || this.state.isSignupBySale) &&
                hasMobileAuthentication && (
                  <Element name="mobile-authentication">
                    <MobileAuthentication
                      onNavigate={this.props.onNavigate}
                      name="Mobile-Authentication"
                      propertyType={this.props.selectedProperty}
                      id="mobile-authentication"
                      className={`child-elem ${
                        this.props.match.params.step ===
                        signupSteps.mobileAuthentication
                          ? 'active'
                          : ''
                      }`}
                      isActive={
                        this.props.match.params.step ===
                        signupSteps.mobileAuthentication
                      }
                      nextLoading={
                        this.props.nextLoading &&
                        this.props.match.params.step ===
                          signupSteps.mobileAuthentication
                      }
                      signupAPIResponse={this.props.signupAPIResponse}
                      saveProgressTrigger={this.props.saveProgressTrigger}
                      openProgressDialog={this.openProgressDialog}
                      isAgentView={this.state.isAgentView}
                    />
                  </Element>
                )}
              <Element name="your-property">
                <YourProperty
                  onNavigate={this.props.onNavigate}
                  name="YourProperty"
                  id="your-property"
                  className={`child-elem ${
                    this.props.match.params.step === signupSteps.yourProperty
                      ? 'active'
                      : ''
                  }`}
                  isActive={
                    this.props.match.params.step === signupSteps.yourProperty
                  }
                  nextLoading={
                    this.props.nextLoading &&
                    this.props.match.params.step === signupSteps.yourProperty
                  }
                  signupAPIResponse={this.props.signupAPIResponse}
                  saveProgressTrigger={this.props.saveProgressTrigger}
                  primarySiteIdentifier={this.props.primarySiteIdentifier}
                  openProgressDialog={this.openProgressDialog}
                  isAgentView={this.state.isAgentView}
                />
              </Element>
              <Element name="your-identification">
                <Identification
                  onNavigate={this.props.onNavigate}
                  name="Identification"
                  id="your-identification"
                  className={`child-elem ${
                    this.props.match.params.step ===
                    signupSteps.yourIdentification
                      ? 'active'
                      : ''
                  }`}
                  isActive={
                    this.props.match.params.step ===
                    signupSteps.yourIdentification
                  }
                  nextLoading={
                    this.props.nextLoading &&
                    this.props.match.params.step ===
                      signupSteps.yourIdentification
                  }
                  signupAPIResponse={this.props.signupAPIResponse}
                  saveProgressTrigger={this.props.saveProgressTrigger}
                  openProgressDialog={this.openProgressDialog}
                  isAgentView={this.state.isAgentView}
                />
              </Element>
            </li>
          )}
          {this.props.match.params.step !== signupSteps.completeSummary && (
            <li className="steps-content">
              <div
                className={`steps-header ${
                  this.props.match.params.step === signupSteps.paymentMethod
                    ? 'active'
                    : ''
                } ${
                  showDirectDebitStep && !this.state.isAgentView ? '' : 'mb-1'
                }`}
              >
                {showDirectDebitStep && !this.state.isAgentView && (
                  <React.Fragment>
                    <AgilityTypography
                      component="h2"
                      variant="h2"
                      className="mb-0"
                    >
                      {showDirectDebitStep
                        ? i18n.t('onboarding.paymentMethod.title')
                        : i18n.t('onboarding.billingDetails.title')}
                    </AgilityTypography>
                    <AgilityTypography
                      component="p"
                      variant="body2"
                      color="textPrimary"
                    >
                      {i18n.t('onboarding.paymentMethod.description')}
                    </AgilityTypography>
                  </React.Fragment>
                )}
              </div>
              {showDirectDebitStep && !this.state.isAgentView && (
                <Element name="payment-method">
                  <PaymentMethod
                    data-test-id="payment-method"
                    onNavigate={this.props.onNavigate}
                    name="PaymentMethod"
                    id="payment-method"
                    visible={true}
                    className={`child-elem ${
                      this.props.match.params.step === signupSteps.paymentMethod
                        ? 'active'
                        : ''
                    }`}
                    isActive={
                      this.props.match.params.step === signupSteps.paymentMethod
                    }
                    nextLoading={
                      this.props.nextLoading &&
                      this.props.match.params.step === signupSteps.paymentMethod
                    }
                    signupAPIResponse={this.props.signupAPIResponse}
                    saveProgressTrigger={this.props.saveProgressTrigger}
                  />
                </Element>
              )}
              {showBillingStep && (
                <Element name="payment-details">
                  <PaymentDetails
                    onNavigate={this.props.onNavigate}
                    name="PaymentDetails"
                    id="payment-details"
                    visible={true}
                    className={`child-elem ${
                      this.props.match.params.step ===
                      signupSteps.paymentDetails
                        ? 'active'
                        : ''
                    }`}
                    isActive={
                      this.props.match.params.step ===
                      signupSteps.paymentDetails
                    }
                    nextLoading={
                      this.props.nextLoading &&
                      this.props.match.params.step ===
                        signupSteps.paymentDetails
                    }
                    signupAPIResponse={this.props.signupAPIResponse}
                    saveProgressTrigger={this.props.saveProgressTrigger}
                    primarySiteIdentifier={this.props.primarySiteIdentifier}
                    openProgressDialog={this.openProgressDialog}
                    isAgentView={this.state.isAgentView}
                  />
                </Element>
              )}
            </li>
          )}
          {this.props.match.params.step !== signupSteps.completeSummary && (
            <li className="steps-content">
              <div
                className={`steps-header ${
                  this.props.match.params.step ===
                  signupSteps.lifeSupportConcession
                    ? 'active'
                    : ''
                }`}
              >
                <AgilityTypography component="h2" variant="h2">
                  {i18n.t('onboarding.lifeSupportConcession.title')}
                </AgilityTypography>
              </div>
              <Element name="life-support-details">
                <LifeSupportConcession
                  propertyType={this.props.selectedProperty}
                  onNavigate={this.props.onNavigate}
                  name="LifeSupportConcession"
                  id="life-support-concession-details"
                  visible={true}
                  className={`child-elem ${
                    this.props.match.params.step ===
                    signupSteps.lifeSupportConcession
                      ? 'active'
                      : ''
                  }`}
                  isActive={
                    this.props.match.params.step ===
                    signupSteps.lifeSupportConcession
                  }
                  nextLoading={
                    this.props.nextLoading &&
                    this.props.match.params.step ===
                      signupSteps.lifeSupportConcession
                  }
                  signupAPIResponse={this.props.signupAPIResponse}
                  saveProgressTrigger={this.props.saveProgressTrigger}
                  primarySiteIdentifier={this.props.primarySiteIdentifier}
                  openProgressDialog={this.openProgressDialog}
                  isAgentView={this.state.isAgentView}
                />
              </Element>
            </li>
          )}

          <li className="steps-content">
            <div
              className={`steps-header ${
                this.props.match.params.step === signupSteps.completeSummary
                  ? 'active'
                  : 'hidden'
              }`}
            >
              <AgilityTypography component="h2" variant="h2">
                {i18n.t('completesummary.header.text')}
              </AgilityTypography>
              <AgilityTypography
                component="p"
                variant="body2"
                color="textPrimary"
                css={css`
                  opacity: 0.6;
                `}
              >
                {i18n.t('completesummary.subheader.text')}
              </AgilityTypography>
            </div>
            <Element name="complete-summary">
              {this.props.match.params.step === signupSteps.completeSummary && (
                <CompleteSummary
                  onNavigate={this.props.onNavigate}
                  name="CompleteSummary"
                  id="complete-summary"
                  visible={true}
                  className={
                    this.props.match.params.step === signupSteps.completeSummary
                      ? 'active'
                      : ''
                  }
                  isActive={
                    this.props.match.params.step === signupSteps.completeSummary
                  }
                  selectedPlans={this.props.selectedPlans}
                  productList={this.props.productList}
                  signupAPIResponse={this.props.signupAPIResponse}
                  saveProgressTrigger={this.props.saveProgressTrigger}
                  primarySiteIdentifier={this.props.primarySiteIdentifier}
                  isEmbeddedNetwork={this.props.isEmbeddedNetwork}
                  customConfig={this.props.customConfig}
                />
              )}
            </Element>
          </li>
        </ul>
        <SaveProgressDialog
          data-test-id="save-progress-dialog1"
          open={this.state.showProgressDialog}
          onSaveProgress={this.onSaveProgress}
          onDismiss={this.onDismiss}
          isEmail={this.state.saveProgressTargetIsEmail}
        />
      </div>
    );
  }
}

export { Wizard };

export default withSnackbar(Wizard);
