/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React from 'react';
import { isEmpty } from 'App/utils/helper';
import { string, array, func, object, number, bool } from 'prop-types';
import i18n from 'i18next';
import './product-plan.scss';
// import PriceFilter from '../PriceFilter';
import PlanDetailsCard from './PlanDetailsCard';
import SiteIdentifier from '../SiteIdentifier';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link, Container, Hidden } from '@material-ui/core';
import {
  LoadingWrapper,
  AgilityGrid,
  AgilityNoRecord,
  AgilityTypography,
  AgilityInfo,
  AgilityInfoDialog,
} from 'Src/AgilityComponents';
import SearchIdentifierDialog from './SearchIdentifierDialog';
import SearchAddressDialog from './SearchAddressDialog';
import ListIdentifierDialog from './ListIdentifierDialog';
import NoIdentifierMessage from './NoIdentifierMessage';
import NoOfferMessage from './NoOfferMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  isSiteIdentifier,
  isNullOrEmpty,
  isPostcode,
} from 'App/utils/validationHelper';
import { Element, scroller } from 'react-scroll';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import cookie from 'js-cookie';
import { propertyTypes } from '../../data';
import { Alert } from '@material-ui/lab';
import SliderArrow from './SliderArrow';
import { sortOffersByPrice } from 'App/customConfig';

const styles = theme => ({
  serviceListItem: {
    background: `${theme.palette.common.white}`,
    border: `1px solid ${theme.palette.grey['300']}`,
    '&.selected': {
      background: theme.palette.primary.selected,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
});

class ProductPlans extends React.Component {
  constructor(props) {
    super(props);
    const { code = '' } = this.props.planFilterData;
    this.state = {
      loadingOffers: false,
      activeSlide: null,
      siteIdentifierLoading: false,
      showSearchIdentifierDialog: false,
      showSearchAddressDialog: false,
      showListIdentifierDialog: false,
      enteredSearchLocation: props.enteredSearchLocation,
      code,
      isExpandedRates: false,
      hasPromoOffer: false,
      noOffersForPromo: false,
      openSolarDialog: true,
      hasSolar: this.props.planFilterData.hasSolar,
    };
  }
  componentDidMount() {
    // const {productType, planFilterData: { selectedProperty }} = this.props;
    // this.props.setDefaultPriceFilters(productType);
    // this.props.fetchCostFrequency().catch(error => {});
    // this.props.fetchUsageCategories(selectedProperty, productType).catch(error => {});
    // user entered a postcode into search box, finding offers by postcode
    if (isPostcode(this.props.enteredSearchLocation)) {
      this.getOffersByPostcode(this.props.enteredSearchLocation);
    } else {
      this.callFetchSiteIdentifierListAPI(
        this.props.productDetails.code,
        this.props.selectedSearchLocation,
        this.props.enteredSearchLocation
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.searchTrigger && !this.props.searchTrigger) {
      // const {productType, planFilterData: { selectedProperty }} = this.props;
      // this.props.setDefaultPriceFilters(this.props.productType);
      // // this.props.fetchCostFrequency().catch(error => {});
      // this.props.fetchUsageCategories(selectedProperty, productType).catch(error => {});
      if (isPostcode(this.props.enteredSearchLocation)) {
        this.getOffersByPostcode(this.props.enteredSearchLocation);
      } else {
        this.callFetchSiteIdentifierListAPI(
          this.props.productDetails.code,
          this.props.selectedSearchLocation,
          this.props.enteredSearchLocation
        );
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.productType !== this.props.productType) {
      if (isPostcode(this.props.enteredSearchLocation)) {
        this.getOffersByPostcode(this.props.enteredSearchLocation);
      } else {
        this.callFetchSiteIdentifierListAPI(
          nextProps.productType,
          this.props.selectedSearchLocation,
          this.props.enteredSearchLocation
        );
      }
    }
    if (
      nextProps.planFilterData.promoCode.code !==
        this.props.planFilterData.promoCode.code &&
      // We don't want to reload the offers if the promo entered is invalid,
      // but on the other hand a blank code with invalid is used to clear the promocode
      !(
        !nextProps.planFilterData.promoCode.isValidCode &&
        nextProps.planFilterData.promoCode.code !== ''
      ) &&
      !isEmpty(nextProps.selectedSiteIdentifier)
    ) {
      const {
        selectedSiteIdentifier: { siteIdentifier } = { siteIdentifier: '' },
        productType,
        planFilterData,
        priceFilter,
      } = nextProps;
      this.callFetchOfferingAPI(
        productType,
        siteIdentifier,
        this.getPromoCode(planFilterData, priceFilter)
      );
    }
  }

  getOffersByPostcode = postcode => {
    const property = propertyTypes.find(
      propertyType =>
        propertyType.code === this.props.planFilterData.selectedProperty
    );
    const { agentEnteredCode } = cookie.get();

    const data = {
      distributor_id: this.props.distributor.id,
      market_segment_id: property.value,
      postcode: postcode,
      api_code: agentEnteredCode || process.env.REACT_APP_API_USER_ID,
      includeSolar: this.state.hasSolar,
      sortBy: this.getSortBy(),
    };

    let promoCode = this.getPromoCode(this.props.planFilterData);
    if (promoCode && promoCode.isValidCode && promoCode.code !== '') {
      data.promo = promoCode.code;
    }

    this.setState({
      loadingOffers: true,
    });
    // search offers by postcode, we don't need siteIdentifier.
    // It is null for first param
    this.props
      .fetchOfferList(null, data, this.props.productType)
      .then(response => {
        this.hideLoadingOffer();
      })
      .catch(error => {
        if (error?.status !== 404) {
          this.props.enqueueSnackbar(i18n.t('offering.loading.error'), {
            variant: 'error',
          });
        }
        this.hideLoadingOffer();
      });
  };

  callFetchSiteIdentifierListAPI = (
    productType,
    selectedSearchLocation,
    identifierId = ''
  ) => {
    let data;
    let callIdentifierAPI;
    this.setState({
      siteIdentifierLoading: true,
    });
    if (!this.props.isEmbeddedNetwork) {
      // Set query params for non-embedded flow
      if (!isEmpty(selectedSearchLocation)) {
        data = { ...selectedSearchLocation };
        callIdentifierAPI = this.props.fetchSiteIdentifierList;
      } else if (isSiteIdentifier(identifierId)) {
        data = {
          siteIdentifier:
            identifierId !== ''
              ? identifierId
              : this.state.enteredSearchLocation,
          propertyType: this.props.planFilterData.selectedProperty,
        };
        callIdentifierAPI = this.props.fetchSiteIdentifierListById;
      } else {
        data = {
          unStructuredAddress: identifierId,
        };
        callIdentifierAPI = this.props.fetchSiteIdentifierList;
      }
      data.productType = productType;
    } else {
      data = {
        code: this.state.code,
      };
      callIdentifierAPI = this.props.fetchSiteIdentifierList;
    }

    callIdentifierAPI(productType, data)
      .then(response => {
        if (!isEmpty(this.props.selectedSiteIdentifier)) {
          this.onSiteIdentifierSelected(this.props.selectedSiteIdentifier);
        } else if (Array.isArray(response) && response.length === 1) {
          this.onSiteIdentifierSelected(response[0]);
        }
        this.hideLoadingIdentifier();
        if (this.props.scrollToAfterLoadingCompleted) {
          this.scrollToElement(this.props.iIndex);
        }
      })
      .catch(error => {
        if (error?.status !== 404) {
          this.props.enqueueSnackbar(i18n.t('siteidentifier.loading.error'), {
            variant: 'error',
          });
        }
        this.hideLoadingIdentifier();
      });
  };
  scrollToElement = idx => {
    if (idx < this.props.numberOfSelectedProducts) {
      setTimeout(() => {
        scroller.scrollTo(`search-result-${idx}`, {
          duration: 1000,
          delay: 10,
          smooth: 'easeInOutQuart',
          isDynamic: true,
          top: 0,
          offset: -120,
        });
      }, 100);
    }
  };
  callFetchOfferingAPI = (productType, siteIdentifier, promoCode, filters) => {
    const data = {
      productType: productType,
      propertyType: this.props.planFilterData.selectedProperty,
      includeSolar: this.state.hasSolar,
      apiCode: this.props.apiCode,
      sortBy: this.getSortBy(),
    };
    if (promoCode && promoCode.isValidCode && promoCode.code !== '') {
      data.promo = promoCode.code;
    }
    if (!data.apiCode && process.env.REACT_APP_API_USER_ID) {
      data.apiCode = process.env.REACT_APP_API_USER_ID;
    }

    this.setState({
      loadingOffers: true,
    });
    this.props
      .fetchOfferList(siteIdentifier, data, productType)
      .then(response => {
        // Offers will be sorted with promo offers first
        let hasPromoOffer =
          this.props.offerList.length > 0 &&
          this.props.offerList[0].promocode !== null;
        this.setState({
          hasPromoOffer: hasPromoOffer,
          noOffersForPromo:
            !hasPromoOffer && promoCode && promoCode.isValidCode,
        });
        this.hideLoadingOffer();
      })
      .catch(error => {
        if (error?.status !== 404) {
          this.props.enqueueSnackbar(i18n.t('offering.loading.error'), {
            variant: 'error',
          });
        }
        this.hideLoadingOffer();
      });
  };
  hideLoadingOffer = () => {
    this.setState({
      loadingOffers: false,
    });
  };
  hideLoadingIdentifier = () => {
    this.setState({
      siteIdentifierLoading: false,
    });
  };
  onRatesClick = () => {
    this.setState({
      isExpandedRates: !this.state.isExpandedRates,
    });
  };
  onPlanSelected = selectedPlan => {
    if (this.props.findingByPostcode) {
      return this.openSearchAddressDialog();
    }
    this.props.setPlanSelection(selectedPlan, this.props.productType);
    this.scrollToElement(this.props.iIndex + 1);
  };
  onPlanUnSelected = () => {
    this.props.setPlanSelection({}, this.props.productType);
  };
  onSiteIdentifierSelected = selectedIdentifier => {
    if (!isEmpty(selectedIdentifier)) {
      this.props.setIdentifier(this.props.productType, selectedIdentifier);
      this.callFetchOfferingAPI(
        this.props.productType,
        selectedIdentifier.siteIdentifier,
        this.getPromoCode(this.props.planFilterData),
        this.props.priceFilter
      );
    }
  };
  getPromoCode = ({
    promoCode: { code, isValidCode } = { code: '', isValidCode: false },
  }) => {
    return { code, isValidCode };
  };
  getSortBy = () => {
    return sortOffersByPrice ? 'low-high' : 'default';
  };
  onFilterSelected = () => {
    this.callFetchOfferingAPI(
      this.props.productType,
      this.props.selectedSiteIdentifier.siteIdentifier,
      this.getPromoCode(this.props.planFilterData),
      this.props.priceFilter
    );
  };
  setSiteIdentifierLoading = siteIdentifierLoading => {
    this.setState({
      siteIdentifierLoading,
    });
  };
  openSearchIdentifierDialog = () => {
    this.setState({
      showSearchIdentifierDialog: true,
    });
  };
  openSearchAddressDialog = () => {
    this.setState({
      showSearchAddressDialog: true,
    });
  };
  closeSearchAddressDialog = () => {
    this.setState({
      showSearchAddressDialog: false,
    });
  };
  onAddressSearchClick = selectedLocation => {
    this.closeSearchAddressDialog();
    const planFilterData = { ...this.props.planFilterData };
    planFilterData['selectedSearchLocation'] = selectedLocation.location;
    planFilterData['enteredSearchLocation'] = selectedLocation.textFieldSearch;
    this.props.setPlanFilters(planFilterData);
    this.props.searchOffersNoClick();
  };
  closeSearchIdentifierDialog = () => {
    this.setState({
      showSearchIdentifierDialog: false,
    });
  };
  onIdentifierSearchClick = identifierCode => {
    this.closeSearchIdentifierDialog();
    this.setState({
      enteredSearchLocation: identifierCode,
    });
    this.removeProductIdentifierInStore();
    this.removeProductPlanInStore();
    this.callFetchSiteIdentifierListAPI(
      this.props.productType,
      {},
      identifierCode
    );
  };
  openListIdentifierDialog = () => {
    this.setState({
      showListIdentifierDialog: true,
    });
  };
  closeListIdentifierDialog = () => {
    this.setState({
      showListIdentifierDialog: false,
    });
  };
  onSiteIdentifierSelectedFromListDialog = selectedIdentifier => {
    this.removeProductPlanInStore();
    this.onSiteIdentifierSelected(selectedIdentifier);
    this.closeListIdentifierDialog();
  };
  showSearchDialogFromListIdentifier = () => {
    this.closeListIdentifierDialog();
    this.openSearchIdentifierDialog();
  };
  removeProductIdentifierInStore = () => {
    if (this.props.allSelectedSiteIdentifier[this.props.productType]) {
      const allSelectedSiteIdentifier = {
        ...this.props.allSelectedSiteIdentifier,
      };
      delete allSelectedSiteIdentifier[this.props.productType];
      this.props.setIdentifierSelectionAction(allSelectedSiteIdentifier);
    }
  };
  removeProductPlanInStore = () => {
    if (this.props.allSelectedPlan[this.props.productType]) {
      const allSelectedPlan = { ...this.props.allSelectedPlan };
      delete allSelectedPlan[this.props.productType];
      this.props.setPlanSelectionAction(allSelectedPlan);
    }
  };

  getFormattedAddress = data => {
    if (!data) return '';
    return data.join(', ');
  };

  onSolarClick = () => {
    // Set redux state so other components can update,
    // and set local state so that we can pass it to the API without having to wait for redux to update
    this.props.setOfferFilterHasSolar(true);
    this.setState({
      openSolarDialog: false,
      hasSolar: true,
    });
    // Note that a postcode will never return solar meters, so there won't be a popup for a postcode, and we only need
    // to do a search for the NMI
    this.callFetchSiteIdentifierListAPI(
      this.props.productDetails.code,
      this.props.selectedSearchLocation,
      this.props.enteredSearchLocation
    );
  };

  render() {
    const {
      productType,
      selectedPlan,
      selectedSiteIdentifier,
      costFrequency,
      usageCategories,
      productDetails,
      iIndex,
    } = this.props;
    const enteredSearchLocation = this.state.enteredSearchLocation;
    const { classes } = this.props;
    var settings = {
      initialSlide: 0,
      slidesToShow: 3,
      slidesToScroll: 3,
      swipeToSlide: true,
      speed: 500,
      autoplay: false,
      infinite: false,
      arrows: true,
      nextArrow: <SliderArrow />,
      prevArrow: <SliderArrow />,
      afterChange: (current, next) => this.setState({ activeSlide: next }),
      // appendDots: dots => (
      //   <div
      //     style={{
      //       backgroundColor: "#ddd",
      //       borderRadius: "10px",
      //       padding: "10px"
      //     }}
      //   >
      //     <ul style={{ margin: "0px" }}>{dots}</ul>
      //   </div>
      // ),
      // customPaging: i => (
      //   <div
      //     style={{
      //       width: "30px",
      //       color: "blue",
      //       border: "1px blue solid"
      //     }}
      //   >
      //     {i + 1}
      //   </div>
      // ),
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          // breakpoint: 600, //actually $small
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            centerPadding: 20,
            variableWidth: true,
          },
        },
      ],
    };

    return (
      <div className={`plans ${iIndex % 2 === 0 ? 'odd-plan' : 'even-plan'}`}>
        <LoadingWrapper
          isLoading={this.state.siteIdentifierLoading}
          loadingMessage={
            <AgilityTypography variant="h4" component="h4" align="center">
              {i18n.t('siteidentifier.loading.message', {
                productType: (productDetails.display || '').toLowerCase(),
                address: enteredSearchLocation,
              })}
            </AgilityTypography>
          }
        >
          {this.props.siteIdentifierList.length > 0 ||
          this.props.findingByPostcode ? (
            <AgilityGrid
              container
              direction="row"
              className="grid-container__wrap"
            >
              {/* {!this.props.isEmbeddedNetwork && false && (
                  <AgilityGrid item xs={12} sm={12} className="flex-no-shrink">
                    <PriceFilter
                      data-test-id={`price-filter-${productType}`}
                      disabled={isEmpty(selectedSiteIdentifier)}
                      onFilterSelected={this.onFilterSelected}
                      costFrequency={costFrequency}
                      usageCategories={usageCategories}
                      productType={productType}
                      productFilterLabel={this.props.productDetails.display}
                    />
                  </AgilityGrid>
                )} */}
              <AgilityGrid item xs={12} sm={12} className="mx-auto">
                <div className="product-plan-wrapper">
                  {/* <AgilityTypography
                      variant="h3"
                      component="h3"
                      align="left"
                      className="heading3-icon service__spacing"
                    >
                      
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-settings"
                      >
                        <defs>
                          <linearGradient id="bolt">
                            <stop className="bolt-stop1" offset="0%"></stop>
                            <stop className="bolt-stop2" offset="100%"></stop>
                          </linearGradient>
                        </defs>
                        <defs>
                          <linearGradient id="fire">
                            <stop className="fire-stop1" offset="0%"></stop>
                            <stop className="fire-stop2" offset="100%"></stop>
                          </linearGradient>
                        </defs>
                      </svg>
                      <FontAwesomeIcon
                        icon={['fas', `${this.props.productDetails.icon}`]}
                        className={`icon ${this.props.productDetails.icon}`}
                      />
                      {!isEmpty(selectedSiteIdentifier)
                        ? i18n.t('compareplan.servicelike.text', {
                            product:
                              this.props.productDetails.display.toLowerCase() ||
                              '',
                          })
                        : i18n.t('compareplan.addressLook.text')}
                    </AgilityTypography> */}
                  <Element name={`search-result-${iIndex}`}>
                    {!isEmpty(selectedSiteIdentifier) ||
                    this.props.findingByPostcode ? (
                      <React.Fragment>
                        {this.state.loadingOffers === false &&
                          this.props.offerList.length !== 0 && (
                            <Container>
                              <AgilityGrid container>
                                <AgilityGrid item xs={12}>
                                  <AgilityInfo
                                    severity="info"
                                    className=""
                                    icon={
                                      <FontAwesomeIcon
                                        icon={[
                                          'fas',
                                          `${this.props.productDetails.icon}`,
                                        ]}
                                        size="sm"
                                        className={`${this.props.productDetails.icon}`}
                                      />
                                    }
                                    action={
                                      this.props.findingByPostcode ? (
                                        false
                                      ) : this.props.siteIdentifierList.length >
                                        1 ? (
                                        <Link
                                          data-test-id="search-using-listidentifier-label"
                                          onClick={() =>
                                            this.openListIdentifierDialog()
                                          }
                                        >
                                          {i18n.t('offering.topinfo.message4')}
                                        </Link>
                                      ) : (
                                        <Link
                                          data-test-id="search-using-identifier-label"
                                          onClick={() =>
                                            this.openSearchIdentifierDialog()
                                          }
                                        >
                                          {i18n.t('offering.topinfo.message2')}{' '}
                                          {productDetails.displayAlternative}?
                                        </Link>
                                      )
                                    }
                                  >
                                    {!isEmpty(selectedSiteIdentifier) && (
                                      <AgilityTypography
                                        variant="body2"
                                        component="span"
                                        css={css`
                                      color: 'inherit',
                                      fontSize: '15px',
                                      margin: '5px 0px',
                                    `}
                                      >
                                        {i18n.t('offering.topinfo.message1', {
                                          productType: (
                                            productDetails.display || ''
                                          ).toLowerCase(),
                                        })}
                                        &nbsp;
                                        <Hidden smUp>
                                          <br />
                                        </Hidden>
                                        <span className="font-bold">
                                          {selectedSiteIdentifier.multilineAddress
                                            .filter(
                                              str => !isNullOrEmpty(str.trim())
                                            )
                                            .join(', ')}
                                          {/* &nbsp;-&nbsp;
                                        {`${this.props.productDetails.displayAlternative}: `}
                                        {maskSiteIdentifier(
                                          selectedSiteIdentifier.siteIdentifier
                                        )} */}
                                        </span>
                                      </AgilityTypography>
                                    )}
                                    {this.props.findingByPostcode && (
                                      <AgilityTypography
                                        variant="body2"
                                        component="span"
                                        css={css`
                                        color: 'inherit',
                                        fontSize: '15px',
                                        margin: '5px 0px',
                                      `}
                                      >
                                        {i18n.t(
                                          'offering.topinfo.postcode.message'
                                        )}
                                        &nbsp;
                                        <span className="font-bold">
                                          {productDetails.display}
                                        </span>
                                      </AgilityTypography>
                                    )}
                                  </AgilityInfo>
                                  {this.state.noOffersForPromo && (
                                    <Alert
                                      severity="info"
                                      color="error"
                                      variant="filled"
                                      icon={false}
                                    >
                                      {i18n.t(
                                        'promocode.errorMessage.noPromoOffersFound',
                                        {
                                          productType: (
                                            productDetails.display || ''
                                          ).toLowerCase(),
                                          promocode:
                                            this.props.planFilterData.promoCode
                                              .code,
                                        }
                                      )}
                                    </Alert>
                                  )}
                                </AgilityGrid>
                              </AgilityGrid>
                            </Container>
                          )}
                        <div className="services mt-2 mb-2">
                          <div className="service-list-carousel">
                            <div
                              className={`service-list ${
                                !this.state.hasPromoOffer ? 'no-promocodes' : ''
                              }`}
                            >
                              <LoadingWrapper
                                isLoading={this.state.loadingOffers}
                                loadingMessage={
                                  <AgilityTypography
                                    variant="h4"
                                    component="h4"
                                    align="center"
                                    className="mb-2"
                                  >
                                    {i18n.t('siteidentifier.loading.message', {
                                      productType: (
                                        productDetails.display || ''
                                      ).toLowerCase(),
                                      address: this.getFormattedAddress(
                                        selectedSiteIdentifier.multilineAddress
                                      ),
                                    })}
                                  </AgilityTypography>
                                }
                              >
                                {this.props.offerList.length !== 0 ? (
                                  <Slider
                                    {...settings}
                                    data-test-id="id-slider"
                                    className={`${
                                      this.props.offerList.length === 1
                                        ? 'services__one-item'
                                        : 'services__multiple-items'
                                    }`}
                                  >
                                    {!isEmpty(this.props.offerList) &&
                                      this.props.offerList.map(
                                        (data, index) => (
                                          <div
                                            data-test-id={`offering-${productType}-${data.id}`}
                                            key={index}
                                            className={`service-list-item ${
                                              classes.serviceListItem
                                            } ${
                                              !isEmpty(selectedPlan) &&
                                              selectedPlan.id === data.id
                                                ? ' selected'
                                                : ''
                                            } ${
                                              data.promocode
                                                ? 'has-promocode'
                                                : ''
                                            }
                                            `}
                                          >
                                            <PlanDetailsCard
                                              data-test-id={`card-${productType}-${data.id}`}
                                              selectedPlan={
                                                this.props.selectedPlan
                                              }
                                              onPlanSelected={
                                                this.onPlanSelected
                                              }
                                              onPlanUnSelected={
                                                this.onPlanUnSelected
                                              }
                                              planDetails={data}
                                              key={data.id}
                                              productDetails={
                                                this.props.productDetails
                                              }
                                              isExpandedRates={
                                                this.state.isExpandedRates
                                              }
                                              onRatesClick={this.onRatesClick}
                                              isPopup={false}
                                              isEmbeddedNetwork={
                                                this.props.isEmbeddedNetwork
                                              }
                                              site={
                                                this.props
                                                  .selectedSiteIdentifier
                                                  .address
                                              }
                                              serviceListPosition={index}
                                              state={
                                                this.props?.distributor
                                                  ?.state_id
                                              }
                                            />
                                          </div>
                                        )
                                      )}
                                  </Slider>
                                ) : (
                                  <Container>
                                    <AgilityNoRecord>
                                      <NoOfferMessage
                                        openSearchIdentifierDialog={
                                          this.openSearchIdentifierDialog
                                        }
                                        enteredSearchLocation={
                                          enteredSearchLocation
                                        }
                                        productDetails={productDetails}
                                        isEmbeddedNetwork={
                                          this.props.isEmbeddedNetwork
                                        }
                                      />
                                    </AgilityNoRecord>
                                    {this.props.productType === 'POWER' &&
                                      this.props.nmiHasSolarMeter &&
                                      !this.state.hasSolar && (
                                        <AgilityInfoDialog
                                          open={this.state.openSolarDialog}
                                          fullWidth
                                          maxWidth="sm"
                                          okText={i18n.t(
                                            'siteIdentifier.solarOffers.okText'
                                          )}
                                          showDeclineButton={false}
                                          okClick={this.onSolarClick}
                                          closeClick={this.onSolarClick}
                                          textAlign="center"
                                        >
                                          {i18n.t(
                                            'siteIdentifier.solarOffers.text'
                                          )}
                                        </AgilityInfoDialog>
                                      )}
                                  </Container>
                                )}
                                {!isEmpty(this.props.offerList) &&
                                  this.props.offerList.map((data, index) =>
                                    data.promocode ? (
                                      <Container>
                                        <AgilityTypography
                                          component="p"
                                          containerClass="promocode-terms mt-2 mb-2"
                                          variant="subtitle1"
                                        >
                                          {i18n
                                            .t('promocode.terms.asterisk')
                                            .repeat(index + 1)}
                                          {i18n.t('promocode.terms.label')}:{' '}
                                          {data.promocode.terms}
                                        </AgilityTypography>
                                      </Container>
                                    ) : (
                                      ''
                                    )
                                  )}
                              </LoadingWrapper>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    ) : (
                      <Container>
                        <AgilityGrid container>
                          <AgilityGrid item sm={12}>
                            <div className="list-identifier">
                              <AgilityInfo
                                severity="info"
                                className=""
                                icon={false}
                              >
                                <AgilityTypography
                                  variant="body2"
                                  component="p"
                                >
                                  {i18n.t('siteidentifier.topinfo.message1', {
                                    productType: (
                                      productDetails.display || ''
                                    ).toLowerCase(),
                                  })}
                                </AgilityTypography>
                                {!this.props.isEmbeddedNetwork && (
                                  <AgilityTypography
                                    variant="body2"
                                    component="p"
                                  >
                                    {i18n.t('siteidentifier.topinfo.message2')}
                                    &nbsp;
                                    <Link
                                      data-test-id="search-ident-id"
                                      onClick={() =>
                                        this.openSearchIdentifierDialog()
                                      }
                                    >
                                      {i18n.t(
                                        'siteidentifier.searchusing.identifier',
                                        {
                                          identifierCode:
                                            productDetails.displayAlternative,
                                        }
                                      )}
                                    </Link>
                                    &nbsp;
                                    {i18n.t('siteidentifier.topinfo.message3', {
                                      productType: (
                                        productDetails.display || ''
                                      ).toLowerCase(),
                                    })}
                                  </AgilityTypography>
                                )}
                              </AgilityInfo>
                              <SiteIdentifier
                                data-test-id={`site-identifier-${productType}`}
                                onSiteIdentifierSelected={
                                  this.onSiteIdentifierSelected
                                }
                                productDetails={this.props.productDetails}
                              />
                            </div>
                          </AgilityGrid>
                        </AgilityGrid>
                      </Container>
                    )}
                  </Element>
                </div>
              </AgilityGrid>
            </AgilityGrid>
          ) : (
            <React.Fragment>
              <Container>
                {/* <AgilityNoRecord> */}
                <AgilityGrid item xs={12} sm={12} className="mx-auto">
                  <NoIdentifierMessage
                    openSearchIdentifierDialog={this.openSearchIdentifierDialog}
                    enteredSearchLocation={enteredSearchLocation}
                    productDetails={productDetails}
                    isEmbeddedNetwork={this.props.isEmbeddedNetwork}
                  />
                </AgilityGrid>
                {/* </AgilityNoRecord> */}
              </Container>
            </React.Fragment>
          )}
        </LoadingWrapper>
        <SearchIdentifierDialog
          data-test-id="search-identifier-dialog"
          open={this.state.showSearchIdentifierDialog}
          productDetails={productDetails}
          onDialogCloseClick={() => this.closeSearchIdentifierDialog()}
          onSearchClick={value => this.onIdentifierSearchClick(value)}
        />
        <SearchAddressDialog
          data-test-id="search-address-dialog"
          open={this.state.showSearchAddressDialog}
          onDialogCloseClick={() => this.closeSearchAddressDialog()}
          onSearchClick={value => this.onAddressSearchClick(value)}
        />
        <ListIdentifierDialog
          data-test-id="list-identifier-dialog"
          siteIdentifierList={this.props.siteIdentifierList}
          open={this.state.showListIdentifierDialog}
          productDetails={productDetails}
          onDialogCloseClick={() => this.closeListIdentifierDialog}
          onSiteIdentifierSelected={this.onSiteIdentifierSelectedFromListDialog}
          selectedSiteIdentifier={this.props.selectedSiteIdentifier}
          onSearchIdentifierClick={this.showSearchDialogFromListIdentifier}
        />
      </div>
    );
  }
}

ProductPlans.propTypes = {
  productType: string,
  offerList: array,
  fetchOfferList: func,
  planFilterData: object,
  setPlanSelection: func,
  productDetails: object,
  priceFilter: object,
  selectedSiteIdentifier: object,
  siteIdentifierList: array,
  enteredSearchLocation: string,
  fetchSiteIdentifierList: func,
  selectedSearchLocation: object,
  setIdentifier: func,
  allSelectedSiteIdentifier: object,
  setIdentifierSelectionAction: func,
  setPlanSelectionAction: func,
  allSelectedPlan: object,
  iIndex: number,
  fetchSiteIdentifierListById: func,
  isEmbeddedNetwork: bool,
  preloadSite: string,
  scrollToAfterLoadingCompleted: bool,
  numberOfSelectedProducts: number,
  distributor: object,
  findingByPostcode: bool,
  setPlanFilters: func,
  searchOffersNoClick: func,
  setOfferFilterHasSolar: func,
  nmiHasSolarMeter: bool,
};
ProductPlans.defaultProps = {
  productType: '',
  offerList: [],
  planFilterData: {},
  productDetails: {},
  priceFilter: {},
  selectedSiteIdentifier: {},
  siteIdentifierList: [],
  enteredSearchLocation: '',
  selectedSearchLocation: {},
  allSelectedSiteIdentifier: {},
  allSelectedPlan: {},
  isEmbeddedNetwork: false,
  preloadSite: '',
  scrollToAfterLoadingCompleted: true,
  numberOfSelectedProducts: 1,
  distributor: {},
  findingByPostcode: false,
  nmiHasSolarMeter: false,
};

export { ProductPlans };

export default withSnackbar(withStyles(styles)(ProductPlans));
