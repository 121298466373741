// import { css, jsx } from '@emotion/react';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import './complete-summary.scss';
import i18n from 'i18next';
import { format } from 'date-fns';
import { array, object, bool, func, number, string } from 'prop-types';
import BasicPriceDetailsWrapper from 'App/pages/ComparePlans/Components/ProductPlans/BasicPriceDetailsWrapper';
import {
  PAYMENT_METHOD_TYPE_CARD,
  PAYMENT_METHOD_TYPE_ACCOUNT,
  SIGNUP_STEPS as signupSteps,
  ACCOUNT_ID,
} from 'App/utils/constants';
import {
  propertyPlanOption,
  preferContact,
  vsiAccessMethod,
  vsiAppointmentTime,
} from 'App/utils/data';
import PaymentCard from '../step3/PaymentCard/PaymentCard';
import {
  AgilityButton,
  AgilityGrid,
  AgilityCard,
  AgilityTypography,
} from 'Src/AgilityComponents';
import PropertyCard from '../step2/YourProperty/PropertyCard';
import dompurify from 'dompurify';
import {
  customMask,
  maskCardDetails,
  dataToMask,
  isEmpty,
  setAllProperties,
} from 'App/utils/helper';
// import PinVerificationDialog from './PinVerificationDialog';
// import { useTheme } from '@material-ui/core/styles';
import {
  showPlanImage,
  showBillingStep,
  marketRetailTermsIsALink,
  showDirectDebitStep,
  disableRentOwnQuestion,
} from 'App/customConfig';
import { isNullOrEmpty } from 'App/utils/validationHelper';
import { PRODUCT } from 'App/utils/constants';
import CustomCheckbox from 'App/components/CustomCheckbox';
import { useSnackbar } from 'notistack';
import MoveInStartDateError from 'App/modules/apiWrapper/MoveInStartDateError';
import { scrollToSection } from 'App/utils/helper';

// from https://github.com/cure53/DOMPurify/issues/317#issuecomment-912474068
// if a _blank is present, add a noopener
// why is this requried: https://web.dev/external-anchors-use-rel-noopener/
const TEMPORARY_ATTRIBUTE = 'data-temp-href-target';
dompurify.addHook('beforeSanitizeAttributes', function (node) {
  if (node.tagName === 'A') {
    if (!node.hasAttribute('target')) {
      node.setAttribute('target', '_self');
    }
    if (node.hasAttribute('target')) {
      node.setAttribute(TEMPORARY_ATTRIBUTE, node.getAttribute('target'));
    }
  }
});
dompurify.addHook('afterSanitizeAttributes', function (node) {
  if (node.tagName === 'A' && node.hasAttribute(TEMPORARY_ATTRIBUTE)) {
    node.setAttribute('target', node.getAttribute(TEMPORARY_ATTRIBUTE));
    node.removeAttribute(TEMPORARY_ATTRIBUTE);
    if (node.getAttribute('target') === '_blank') {
      node.setAttribute('rel', 'noopener');
    }
  }
});

const sanitizer = dompurify.sanitize;

const generateInputData = (data, summaryData) => {
  return {
    ...data,
    currentPage: signupSteps.completeSummary,
    acceptedPrivacyTerms: summaryData['checkBoxPrivacy'],
    acceptedContractTerms: summaryData['checkBoxContract'],
    acceptedEICTerms: summaryData['checkBoxEIC'],
    acceptedMarketingTerms: summaryData['checkBoxMarketing'],
    acceptedInfoTerms: summaryData['checkBoxImportantInfo'],
    acceptedCreditReport: summaryData['checkBoxCreditReport'],
    acceptedHasInterestOnInterenet:
      summaryData['checkBoxHasInterestOnInternet'],
    acceptedRequiresSmartMeterUpgrade:
      summaryData['checkBoxRequiresSmartMeterUpgrade'],
  };
};

const PRODUCT_TYPE_POWER = 'POWER';

const CompleteSummary = ({
  saveProgressTrigger,
  isActive,
  signupAPIResponse,
  onNavigate,
  productList,
  paymentFrequencyList,
  concessionCardList,
  creditCardDetails,
  showInfo,
  isEmbeddedNetwork,
  idTypeList,
  stateList,
  selectedPlans,
  id,
  className,
  primarySiteIdentifier,
  getCreditCardDetails,
  putSignupDetails,
  fetchMoveInDatesList,
  sendCustomerLog,
  customConfig,
  brandConfig,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [summaryFields, setSummaryFields] = useState({
    checkBoxMarketing: false,
    checkBoxPrivacy: false,
    // Change fields that aren't be displayed to null, so that the value won't through as false,
    // which will look like the terms were declined
    checkBoxEIC: customConfig.hasEICTermsCheckbox ? false : null,
    checkBoxContract: false,
    checkBoxCreditReport: customConfig.showCreditCheckTerms ? false : null,
    checkBoxImportantInfo: false,
    checkBoxHasInterestOnInternet: false,
    checkBoxRequiresSmartMeterUpgrade: null,
  });
  const [summaryFieldsErrors, setSummaryFieldsErrors] = useState({
    checkBoxPrivacy: false,
    checkBoxEIC: false,
    checkBoxContract: false,
    checkBoxCreditReport: false,
    checkBoxImportantInfo: false,
    checkBoxRequiresSmartMeterUpgrade: false,
  });
  const [summaryFieldsTouch, setSummaryFieldsTouch] = useState({
    checkBoxPrivacy: false,
    checkBoxEIC: false,
    checkBoxContract: false,
    checkBoxCreditReport: false,
    checkBoxImportantInfo: false,
    checkBoxRequiresSmartMeterUpgrade: false,
  });
  // const [showPinVerification, setShowPinVerification] = useState(false);
  // const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [termsHtml, setTermsHtml] = useState('');
  const [eicContent, setEicContent] = useState('');
  const [vsiInfo, setVsiInfo] = useState({});
  const [isFirstEnergy] = useState(
    process.env.REACT_APP_BRAND_CODE === 'FIRST'
  );
  const [isNbe] = useState(process.env.REACT_APP_BRAND_CODE === 'NBE');

  const prevPropRef = useRef();
  useEffect(() => {
    if (
      prevPropRef.current &&
      isActive &&
      prevPropRef.current !== saveProgressTrigger
    ) {
      const data = generateInputData(signupAPIResponse, summaryFields);
      onNavigate('progress', data, true);
    }
    prevPropRef.current = saveProgressTrigger;
  }, [
    saveProgressTrigger,
    isActive,
    onNavigate,
    signupAPIResponse,
    summaryFields,
  ]);

  useEffect(() => {
    if (
      summaryFieldsErrors.checkBoxContract ||
      summaryFieldsErrors.checkBoxEIC ||
      summaryFieldsErrors.checkBoxPrivacy ||
      summaryFieldsErrors.checkBoxCreditReport ||
      summaryFieldsErrors.checkBoxImportantInfo
    ) {
      scrollToSection('marketingPermission');
    }
  });

  const checkBoxHandler = (value, name) => {
    let context = '';
    switch (name) {
      case 'checkBoxMarketing':
        context = i18n.t('completesummary.tnc.marketing.log');
        break;
      case 'checkBoxPrivacy':
        context = i18n.t('completesummary.tnc.privacy.log');
        break;
      case 'checkBoxImportantInfo':
        context = i18n.t('completesummary.tnc.contractInfo.log');
        break;
      case 'checkBoxCreditReport':
        context = i18n.t('completeSummary.creditReportingStatement.log');
        break;
      case 'checkBoxEIC':
        context = i18n.t('completesummary.tnc.eic.log');
        break;
      case 'checkBoxHasInterestOnInternet':
        context = i18n.t('completesummary.tnc.hasInterestOnInternet.log');
        break;
      case 'checkBoxRequiresSmartMeterUpgrade':
        context = i18n.t('completesummary.tnc.requiresSmartMeterUpgrade.log');
        break;
      case 'checkBoxContract':
      default:
        context = i18n.t('completesummary.tnc.contract.log');
    }
    sendCustomerLog({
      ...signupAPIResponse,
      action: value
        ? 'ACCEPT_TERMS_AND_CONDITIONS'
        : 'DECLINE_TERMS_AND_CONDITIONS',
      context: context,
    });

    if (value && isNbe && name === 'checkBoxContract') {
      window.open(process.env.REACT_APP_TERMS_CONDITION, '_blank');
    }

    let newFields = { ...summaryFields, [name]: value };
    let newTouched = { ...summaryFieldsTouch, [name]: true };
    setSummaryFields(newFields);
    setSummaryFieldsTouch(newTouched);
    validateForm(newTouched, newFields);
  };

  const createAccount = () => {
    const updatedSummaryFieldsTouched = setAllProperties(
      summaryFieldsTouch,
      true
    );
    setSummaryFieldsTouch(updatedSummaryFieldsTouched);
    const isFormValid = validateForm(
      updatedSummaryFieldsTouched,
      summaryFields
    );
    if (isFormValid) {
      const data = generateInputData(signupAPIResponse, summaryFields);
      data.context = 'Complete sign up';
      putSignupDetails(data)
        .then(() => {
          props.history.push('/signup-completion');
        })
        .catch(error => {
          if (error instanceof MoveInStartDateError) {
            enqueueSnackbar(error.message, {
              variant: 'warning',
              autoHideDuration: 10000,
            });
            fetchMoveInDatesList(localStorage.getItem(ACCOUNT_ID));
            data.currentPage = signupSteps.yourProperty;
            onNavigate('goto', data);
          }
        });
    }
  };

  // NOTE: REMOVED FOR SEG
  // const sendPinValidationEmail = () => {
  //   for (const key in summaryFieldsTouch) {
  //     summaryFieldsTouch[key] = true;
  //   }
  //   const isFormValid = validateForm();
  //   if(isFormValid){
  //     setIsSendingEmail(true);
  //     props.requestValidationEmail(signupAPIResponse.applicationId)
  //       .then(resp => {
  //         if(resp){
  //           setShowPinVerification(true);
  //         }
  //         setIsSendingEmail(false);
  //       })
  //       .catch(() => {
  //         setIsSendingEmail(false);
  //       });
  //   }
  // }

  const validateForm = currentTouched => {
    let isFormValid = true;
    const summaryFieldsErrors = {};
    if (currentTouched.checkBoxPrivacy && !brandConfig.isTango) {
      if (summaryFields.checkBoxPrivacy === false) {
        isFormValid = false;
        summaryFieldsErrors.checkBoxPrivacy = true;
      }
    }
    if (currentTouched.checkBoxEIC && customConfig.hasEICTermsCheckbox) {
      if (summaryFields.checkBoxEIC === false) {
        isFormValid = false;
        summaryFieldsErrors.checkBoxEIC = true;
      }
    }

    if (currentTouched.checkBoxContract) {
      if (summaryFields.checkBoxContract === false) {
        isFormValid = false;
        summaryFieldsErrors.checkBoxContract = true;
      }
    }

    if (isNbe && marketRetailTermsIsALink) {
      if (currentTouched.checkBoxImportantInfo) {
        if (summaryFields.checkBoxImportantInfo === false) {
          isFormValid = false;
          summaryFieldsErrors.checkBoxImportantInfo = true;
        }
      }
    }

    if (customConfig.showCreditCheckTerms) {
      if (currentTouched.checkBoxCreditReport) {
        if (summaryFields.checkBoxCreditReport === false) {
          isFormValid = false;
          summaryFieldsErrors.checkBoxCreditReport = true;
        }
      }
    }
    if (selectedPlans[PRODUCT.ELECTRICITY]?.requiresSmartMeterUpgrade) {
      if (currentTouched.checkBoxRequiresSmartMeterUpgrade) {
        if (summaryFields.checkBoxRequiresSmartMeterUpgrade !== true) {
          isFormValid = false;
          summaryFieldsErrors.checkBoxRequiresSmartMeterUpgrade = true;
        }
      }
    }

    setSummaryFieldsErrors(summaryFieldsErrors);
    return isFormValid;
  };

  let primaryContact = null;
  let secondaryContact = null;
  let identificationDetails = {};
  let transferDetail = {};
  let postalAddress = {};
  let paymentDetails = {};
  let concessionCardDetail = {};
  let organisation = null;
  let electricityService = {};
  if (signupAPIResponse) {
    organisation = signupAPIResponse.organisation;
    primaryContact = signupAPIResponse.contacts.find(c => c.isPrimary === 1);
    secondaryContact = signupAPIResponse.contacts.find(c => c.isPrimary === 0);
    identificationDetails = signupAPIResponse.identifications
      ? signupAPIResponse.identifications[0]
      : {};
    transferDetail = signupAPIResponse.transferDetail;
    postalAddress = signupAPIResponse.postalAddress;
    paymentDetails = signupAPIResponse.paymentDetails;
    concessionCardDetail = signupAPIResponse.concessionCardDetail;
    electricityService = signupAPIResponse.services.find(
      s => s.productType === PRODUCT.ELECTRICITY
    );

    if (primaryContact?.phones) {
      primaryContact.mobilePhone = primaryContact.phones.find(
        p => p.type === 'Mobile'
      );
      primaryContact.homePhone = primaryContact.phones.find(
        p => p.type === 'Landline'
      );
    }
  }

  useEffect(() => {
    if (!isEmpty(signupAPIResponse) && signupAPIResponse.services !== null) {
      const services = signupAPIResponse.services;
      services.forEach(obj => {
        if (
          obj.productType === PRODUCT_TYPE_POWER &&
          !isEmpty(obj.vsiAppointmentTime)
        ) {
          const appointmentTime = vsiAppointmentTime.find(
            appointmentTime => appointmentTime.value === obj.vsiAppointmentTime
          );
          const accessMethod = vsiAccessMethod.find(
            accessMethod => accessMethod.value === obj.vsiAccessMethod
          );

          setVsiInfo({
            vsiAppointmentTime: appointmentTime?.label,
            vsiAccessMethod: accessMethod?.label,
            meterAccess: obj.meterAccess,
          });
        }
      });
    }
  }, [signupAPIResponse]);

  const getPaymentFrequency = () => {
    if (
      electricityService?.billingFrequency &&
      paymentFrequencyList.length > 0
    ) {
      return getLabelValue(
        paymentFrequencyList,
        electricityService.billingFrequency
      );
    }
    return '';
  };

  const getConcessionCardName = () => {
    if (concessionCardDetail?.cardTypeCode && concessionCardList.length > 0) {
      return getLabelValue(
        concessionCardList,
        concessionCardDetail.cardTypeCode
      );
    }
    return '';
  };

  const getIdentificationType = () => {
    if (identificationDetails?.identificationType && idTypeList.length > 0) {
      return getLabelValue(
        idTypeList,
        identificationDetails.identificationType
      );
    }
    return '';
  };

  const getBankAccountNumber = (bankAccountNumber, mask) => {
    const value = bankAccountNumber;
    if (isNullOrEmpty(value)) {
      return '';
    }
    const maskArray = customMask(mask);
    return dataToMask(value, maskArray);
  };

  const getLabelValue = (list, matcher, placeholder = '') => {
    const _data = list.find(x => x.value === matcher);
    return _data ? _data.label : placeholder;
  };

  //TODO REMOVE HARDCODED
  const isStandingOffer = useCallback(() => {
    return Object.values(selectedPlans).some(x =>
      x.name.toLowerCase().indexOf('standing')
    );
  }, [selectedPlans]);

  useEffect(() => {
    if (
      typeof selectedPlans === 'object' &&
      Object.keys(selectedPlans).length > 0 &&
      !isNullOrEmpty(selectedPlans[Object.keys(selectedPlans)[0]].termsHtml)
    ) {
      return setTermsHtml(
        selectedPlans[Object.keys(selectedPlans)[0]].termsHtml
      );
    }

    setTermsHtml(
      i18n.t(
        isStandingOffer()
          ? 'completesummary.standingOffer.text'
          : 'completesummary.contract.text',
        {
          interpolation: { escapeValue: false },
        }
      )
    );
  }, [selectedPlans, isStandingOffer]);

  const signupDate = format(new Date(), 'do MMM, yyyy');

  useEffect(() => {
    setEicContent(
      (selectedPlans[PRODUCT.ELECTRICITY]?.siteScriptHtml || '') +
        (selectedPlans[PRODUCT.GAS]?.siteScriptHtml || '') +
        i18n.t('completesummary.tnc.eic.content', {
          interpolation: { escapeValue: false },
        })
    );
  }, [selectedPlans]);

  return (
    <React.Fragment>
      {!isEmpty(signupAPIResponse) && (
        <AgilityCard className={`steps-wrapper ${className}`} id={id}>
          <AgilityTypography
            variant="h3"
            component="h3"
            className="mb-2"
            align="left"
          >
            {i18n.t('completesummary.subheader.subText')}
          </AgilityTypography>
          <form
            autoComplete="off"
            noValidate
            data-test-id="completeSummaryForm"
          >
            <AgilityTypography component="h5" variant="h5" className="mb-2">
              {i18n.t('completesummary.yourplan.text')}
            </AgilityTypography>
            <BasicPriceDetailsWrapper
              data-test-id="basic-price-details-wrapper"
              signupAPIResponse={signupAPIResponse}
              site={signupAPIResponse.address}
              productList={productList}
              selectedPlans={selectedPlans}
              displayProductHeading={false}
              showImage={showPlanImage}
              displayProduct
              isEmbeddedNetwork={isEmbeddedNetwork}
              inCompleteSummary={true}
            />

            <table className="detail-table">
              <tbody>
                <tr>
                  <td>{i18n.t('completesummary.signupDate.text')}</td>
                  <td className="bold">{signupDate}</td>
                </tr>
              </tbody>
            </table>

            <AgilityTypography
              component="h5"
              variant="h5"
              className="mb-2"
              color="primary"
            >
              {i18n.t('completesummary.aboutyou.text')}
            </AgilityTypography>
            <table className="detail-table">
              <tbody>
                {organisation && (
                  <React.Fragment>
                    {organisation.name && (
                      <tr>
                        <td>
                          {i18n.t('completesummary.aboutyou.businessName')}
                        </td>
                        <td
                          data-test-id="about-you-business-name"
                          className="bold"
                        >
                          {organisation.name}
                        </td>
                      </tr>
                    )}
                    {organisation.trading_name && (
                      <tr>
                        <td>
                          {i18n.t('completesummary.aboutyou.tradingName')}
                        </td>
                        <td
                          data-test-id="about-you-trading-name"
                          className="bold"
                        >
                          {organisation.trading_name}
                        </td>
                      </tr>
                    )}
                    {organisation.organisationType.description && (
                      <tr>
                        <td>
                          {i18n.t('completesummary.aboutyou.businessType')}
                        </td>
                        <td
                          data-test-id="about-you-business-type"
                          className="bold"
                        >
                          {organisation.organisationType.description}
                        </td>
                      </tr>
                    )}
                    {organisation.abn && (
                      <tr>
                        <td>{i18n.t('completesummary.aboutyou.abn')}</td>
                        <td data-test-id="about-you-abn" className="bold">
                          {organisation.abn}
                        </td>
                      </tr>
                    )}
                    {organisation.acn && (
                      <tr>
                        <td>{i18n.t('completesummary.aboutyou.acn')}</td>
                        <td data-test-id="about-you-acn" className="bold">
                          {organisation.acn}
                        </td>
                      </tr>
                    )}
                    {organisation.trustee_name && (
                      <tr>
                        <td>
                          {i18n.t('completesummary.aboutyou.trusteeName')}
                        </td>
                        <td
                          data-test-id="about-you-trusteeName"
                          className="bold"
                        >
                          {organisation.trustee_name}
                        </td>
                      </tr>
                    )}
                    {organisation && (
                      <tr>
                        <td>
                          {i18n.t('completesummary.aboutyou.gstRegistered')}
                        </td>
                        <td className="bold">
                          {organisation.gst_registered_at ? 'Yes' : 'No'}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                )}
                <tr>
                  <td>{i18n.t('completesummary.aboutyou.name')}</td>
                  <td data-test-id="about-you-name" className="bold">
                    {primaryContact.firstName}
                    {primaryContact.middleName
                      ? ` ${primaryContact.middleName}`
                      : ''}
                    {primaryContact.lastName !== ''
                      ? ` ${primaryContact.lastName}`
                      : ''}
                  </td>
                </tr>
                <tr>
                  <td>{i18n.t('completesummary.aboutyou.email')}</td>
                  <td data-test-id="about-you-email" className="bold">
                    {primaryContact.emailAddress}
                  </td>
                </tr>
                {primaryContact.mobilePhone && (
                  <tr>
                    <td>{i18n.t('completesummary.aboutyou.mobilePhone')}</td>
                    <td data-test-id="about-you-mobile-number" className="bold">
                      {primaryContact.mobilePhone.phoneNumber}
                    </td>
                  </tr>
                )}

                {primaryContact.homePhone && (
                  <tr>
                    <td>{i18n.t('completesummary.aboutyou.homePhone')}</td>
                    <td data-test-id="about-you-home-number" className="bold">
                      {primaryContact.homePhone.phoneNumber}
                    </td>
                  </tr>
                )}
                {customConfig.showPreferContact && (
                  <tr>
                    <td>{i18n.t('completesummary.aboutyou.contact')}</td>
                    <td data-test-id="about-preferred-contact" className="bold">
                      {getLabelValue(
                        preferContact,
                        primaryContact.contactPreference,
                        '-'
                      )}
                    </td>
                  </tr>
                )}
                <tr>
                  <td>{i18n.t('completesummary.aboutyou.dob')}</td>
                  <td data-test-id="about-you-dob" className="bold">
                    {primaryContact.dateOfBirth &&
                      format(
                        new Date(primaryContact.dateOfBirth),
                        window.dateFormat
                      )}
                  </td>
                </tr>
                {identificationDetails.identificationNumber && (
                  <tr>
                    <td data-test-id="about-you-identification-type">
                      {getIdentificationType()}
                    </td>
                    <td
                      data-test-id="about-you-identification-number"
                      className="bold"
                    >
                      {identificationDetails.identificationNumber}
                    </td>
                  </tr>
                )}
                {identificationDetails.identificationExpiry && (
                  <tr>
                    <td>{i18n.t('completesummary.aboutyou.expiry')}</td>
                    <td data-test-id="about-you-ident-expiry" className="bold">
                      {identificationDetails.identificationExpiry &&
                        format(
                          new Date(identificationDetails.identificationExpiry),
                          window.dateFormat
                        )}
                    </td>
                  </tr>
                )}

                {secondaryContact && (
                  <tr>
                    <td>{i18n.t('completesummary.aboutyou.secondaryName')}</td>
                    <td
                      data-test-id="about-you-secondary-name"
                      className="bold"
                    >
                      {secondaryContact.firstName}
                      {secondaryContact.middleName
                        ? ` ${secondaryContact.middleName}`
                        : ''}
                      {secondaryContact.lastName !== ''
                        ? ` ${secondaryContact.lastName}`
                        : ''}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <AgilityTypography
              component="h5"
              variant="h5"
              className="mb-2"
              color="primary"
            >
              {i18n.t('completesummary.property.text')}
            </AgilityTypography>
            <PropertyCard
              className="mb-2"
              data-test-id="summary-property-card"
              primarySiteIdentifier={primarySiteIdentifier}
              productList={productList}
              services={signupAPIResponse.services}
              showInfo={showInfo}
            />
            <table className="detail-table">
              <tbody>
                <tr>
                  <td>{i18n.t('completesummary.property.yourplan')}</td>
                  <td data-test-id="summary-your-plan" className="bold">
                    {transferDetail &&
                      transferDetail.transferType &&
                      getLabelValue(
                        propertyPlanOption,
                        transferDetail.transferType,
                        '-'
                      )}
                  </td>
                </tr>
                {transferDetail &&
                  transferDetail.transferDate !== null &&
                  transferDetail.transferDate !== '' && (
                    <tr>
                      <td>
                        {i18n.t(
                          transferDetail.transferType === 'MoveIn'
                            ? 'completesummary.property.moveindate'
                            : 'completesummary.property.transferDate'
                        )}
                      </td>
                      <td data-test-id="summary-moveindate" className="bold">
                        {format(
                          new Date(transferDetail.transferDate),
                          window.dateFormat
                        )}
                      </td>
                    </tr>
                  )}
                {vsiInfo && !isEmpty(vsiInfo.vsiAppointmentTime) && (
                  <>
                    <tr>
                      <td>
                        {i18n.t('completesummary.property.vsiAppointmentTime')}
                      </td>
                      <td data-test-id="summary-moveindate" className="bold">
                        {vsiInfo.vsiAppointmentTime}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {i18n.t('completesummary.property.vsiAccessMethod')}
                      </td>
                      <td data-test-id="summary-moveindate" className="bold">
                        {vsiInfo.vsiAccessMethod}
                      </td>
                    </tr>
                    <tr>
                      <td>{i18n.t('completesummary.property.meterAccess')}</td>
                      <td data-test-id="summary-moveindate" className="bold">
                        {vsiInfo.meterAccess}
                      </td>
                    </tr>
                  </>
                )}
                {signupAPIResponse.hasDifferentPostalAddress && (
                  <React.Fragment>
                    <tr>
                      <td>
                        {i18n.t('completesummary.property.postaladdress')}
                      </td>
                      <td
                        data-test-id="summary-postal-address"
                        className="bold"
                      >
                        <span>
                          {postalAddress.streetAddress}
                          <br />
                          {postalAddress.suburb}
                          <br />
                          {postalAddress.state &&
                            getLabelValue(stateList, postalAddress.state, '-')}
                          ,&nbsp;{postalAddress.postcode}
                        </span>
                      </td>
                    </tr>
                  </React.Fragment>
                )}
                {!disableRentOwnQuestion && (
                  <tr>
                    <td>{i18n.t('completesummary.property.rent')}</td>
                    <td data-test-id="summary-owner-type" className="bold">
                      {signupAPIResponse.propertyOwner
                        ? i18n.t('yourProperty.owned.text')
                        : i18n.t('yourProperty.rent.text')}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {showBillingStep && paymentDetails && (
              <AgilityTypography component="h5" variant="h5" className="mb-2">
                {i18n.t('completesummary.paymentdetails.text')}
              </AgilityTypography>
            )}
            {paymentDetails &&
              paymentDetails.paymentMethod === PAYMENT_METHOD_TYPE_CARD && (
                <PaymentCard
                  paymentCardInfo={{
                    cardName: i18n.t('paymentMethod.card.title'),
                    cardholderName: creditCardDetails.cardholderName,
                    cardNumber: maskCardDetails(creditCardDetails.cardNumber),
                    cardIcon:
                      creditCardDetails.cardIcon ??
                      `cc-${creditCardDetails.cardType}`,
                  }}
                  showRemove={false}
                  data-test-id="summary-credit-card"
                />
              )}
            {paymentDetails &&
              paymentDetails.paymentMethod === PAYMENT_METHOD_TYPE_ACCOUNT && (
                <PaymentCard
                  data-test-id="summary-bank-details"
                  showRemove={false}
                  paymentCardInfo={{
                    cardName: i18n.t('paymentMethod.bank.title'),
                    cardholderName: paymentDetails.bankAccount.accountName,
                    cardNumber: getBankAccountNumber(
                      paymentDetails.bankAccount.accountNumber,
                      process.env.REACT_APP_BANK_ACCOUNT_VALID_FORMAT
                    ),
                    cardIcon: ['fas', 'hand-holding-usd'],
                    bankIcon: '',
                    bankBsb: getBankAccountNumber(
                      paymentDetails.bankAccount.bankStateBranchNumber,
                      process.env.REACT_APP_BSB_VALID_FORMAT
                    ),
                  }}
                  className="bank-account"
                />
              )}
            <table className="detail-table">
              <tbody>
                {!paymentDetails && showDirectDebitStep && (
                  <tr>
                    <td>{i18n.t('completesummary.paymentdetails.text')}</td>
                    <td
                      data-test-id="summary-no-payment-details"
                      className="bold"
                    >
                      {i18n.t('completesummary.paymentdetails.none')}
                    </td>
                  </tr>
                )}
                {customConfig.showBillingFrequency && (
                  <tr>
                    <td>
                      {i18n.t('completesummary.paymentdetail.payfrequency')}
                    </td>
                    <td data-test-id="summary-pay-freq" className="bold">
                      {getPaymentFrequency()}
                    </td>
                  </tr>
                )}
                {customConfig.showBillingStep && (
                  <tr>
                    <td>{i18n.t('completesummary.paymentdetail.billtype')}</td>
                    <td data-test-id="summary-rec-bill" className="bold">
                      {signupAPIResponse.correspondenceDelivery}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {/* Life Support and Concession */}
            <table className="detail-table">
              <tbody>
                {/* Life Support information */}
                <tr>
                  <td>
                    {i18n.t('lifeSupportConcession.lifeSupport.placeholder')}
                  </td>
                  <td data-test-id="has-life-support-person" className="bold">
                    {signupAPIResponse.hasLifeSupportPerson.toUpperCase()}
                  </td>
                </tr>
                {/* Concession Card information */}
                {signupAPIResponse.hasConcessionCard && (
                  <>
                    <tr>
                      <td>
                        {i18n.t('completesummary.paymentdetail.cardnumber')}
                      </td>
                      <td data-test-id="summary-card-number" className="bold">
                        {concessionCardDetail.cardNumber}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {i18n.t('completesummary.paymentdetail.cardtype')}
                      </td>
                      <td data-test-id="summary-card-type" className="bold">
                        {getConcessionCardName()}
                      </td>
                    </tr>
                    {!isEmpty(concessionCardDetail?.expiryDate) && (
                      <tr>
                        <td>
                          {i18n.t('completesummary.consession.cardexpiry')}
                        </td>
                        <td data-test-id="consession-expiry" className="bold">
                          {format(
                            new Date(concessionCardDetail.expiryDate),
                            window.dateFormat
                          )}
                        </td>
                      </tr>
                    )}
                    {/* Medical Cooling Concession information */}
                    <tr>
                      <td>
                        {i18n.t(
                          'lifeSupportConcession.header.medicalCoolingConcession'
                        )}
                      </td>
                      <td
                        data-test-id="has-life-support-person"
                        className="bold"
                      >
                        {signupAPIResponse.hasCoolingConcession ? 'YES' : 'NO'}
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>

            {/* Marketing Permission - No Dialog */}
            <AgilityGrid id="marketingPermission" item xs={12}>
              <CustomCheckbox
                testId="checkBoxMarketing"
                value={summaryFields.checkBoxMarketing}
                onChange={isChecked =>
                  checkBoxHandler(isChecked, 'checkBoxMarketing')
                }
                labelTestId="checkBoxMarketing-label"
                labelText={i18n.t('completesummary.tnc.marketing.text')}
              />
              {/* It's just 1st energy requirement */}
              {isFirstEnergy && (
                <AgilityTypography
                  component="div"
                  variant="subtitle2"
                  className="mx-1"
                  id="marketing-tip"
                  dangerouslySetInnerHTML={{
                    __html: i18n.t('completesummary.tnc.marketing.tip'),
                  }}
                />
              )}
            </AgilityGrid>

            {/* T&C/Privacy Policy - Has Dialog */}
            {!brandConfig.isTango && (
              <AgilityGrid item xs={12}>
                <CustomCheckbox
                  testId="checkBoxPrivacy"
                  value={summaryFields.checkBoxPrivacy}
                  onChange={isChecked =>
                    checkBoxHandler(isChecked, 'checkBoxPrivacy')
                  }
                  labelTestId="checkBoxPrivacy-label"
                  labelText={sanitizer(
                    i18n.t('completesummary.tnc.privacy.text', {
                      interpolation: { escapeValue: false },
                    })
                  )}
                  hasError={summaryFieldsErrors.checkBoxPrivacy}
                  hasDialog={true}
                  dialogTitle={i18n.t('completesummary.tnc.privacy.header')}
                  dialogTestId="summary-privacy-dialog"
                  dialogContents={[
                    {
                      id: 'privacy-policy-text',
                      body: i18n.t('completesummary.tnc.privacy.content', {
                        interpolation: { escapeValue: false },
                      }),
                      variant: 'subtitle1',
                      element: 'label',
                    },
                  ]}
                />
              </AgilityGrid>
            )}

            {/* Contract Offer Checkbox - Has Dialog when `marketRetailTermsIsALink` is false */}
            <AgilityGrid item xs={12}>
              <CustomCheckbox
                testId="checkBoxContract"
                value={summaryFields.checkBoxContract}
                onChange={isChecked =>
                  checkBoxHandler(isChecked, 'checkBoxContract')
                }
                labelTestId="checkBoxContract-label"
                labelText={sanitizer(
                  i18n.t('completesummary.tnc.contract.text', {
                    companyName: process.env.REACT_APP_COPYRIGHT_COMPANY,
                    interpolation: { escapeValue: false },
                  })
                )}
                hasError={summaryFieldsErrors.checkBoxContract}
                hasDialog={!marketRetailTermsIsALink}
                dialogTitle={i18n.t('completesummary.tnc.contract.header')}
                dialogTestId="summary-contract-dialog"
                dialogContents={[
                  {
                    id: 'contract-text',
                    body: termsHtml,
                    variant: 'subtitle1',
                    element: 'label',
                  },
                ]}
              />
            </AgilityGrid>

            {isNbe && marketRetailTermsIsALink && (
              <AgilityGrid item xs={12}>
                <CustomCheckbox
                  testId="checkBoxContract"
                  value={summaryFields.checkBoxImportantInfo}
                  onChange={isChecked =>
                    checkBoxHandler(isChecked, 'checkBoxImportantInfo')
                  }
                  labelTestId="checkBoxContract-label"
                  labelText={i18n.t('completesummary.tnc.contractInfo.text')}
                  hasError={summaryFieldsErrors.checkBoxImportantInfo}
                  hasDialog={true}
                  dialogTitle={i18n.t('completesummary.tnc.contract.header')}
                  dialogTestId="summary-contract-dialog"
                  dialogContents={[
                    {
                      id: 'contract-text',
                      body: termsHtml,
                      variant: 'subtitle1',
                      element: 'label',
                    },
                  ]}
                />
              </AgilityGrid>
            )}

            {/* Credit Check T&C - No Dialog */}
            {customConfig.showCreditCheckTerms && (
              <AgilityGrid item xs={12}>
                <CustomCheckbox
                  testId="checkBoxCreditReport"
                  value={summaryFields.checkBoxCreditReport}
                  onChange={isChecked =>
                    checkBoxHandler(isChecked, 'checkBoxCreditReport')
                  }
                  labelTestId="checkBoxCreditReport-label"
                  labelText={i18n.t(
                    'completeSummary.creditReportingStatement.text',
                    {
                      interpolation: { escapeValue: false },
                    }
                  )}
                  labelSuffixLink={
                    process.env.REACT_APP_CREDIT_REPORT_STATEMENT_LINK
                  }
                  labelSuffixLinkText={i18n.t(
                    'completeSummary.creditReportingStatement.link.text'
                  )}
                  hasError={summaryFieldsErrors.checkBoxCreditReport}
                  hasDialog={customConfig.hasCreditReportDialog}
                  dialogTestId="summary-privacy-dialog"
                  dialogContents={[
                    {
                      id: 'credit-report-statement-text',
                      body: i18n.t(
                        'completesummary.creditReportingStatement.content',
                        {
                          interpolation: { escapeValue: false },
                        }
                      ),
                      variant: 'subtitle1',
                      element: 'label',
                    },
                  ]}
                />
              </AgilityGrid>
            )}

            {/* EIC - Has Dialog */}
            {customConfig.hasEICTermsCheckbox && (
              <AgilityGrid item xs={12}>
                <CustomCheckbox
                  testId="checkBoxEIC"
                  value={summaryFields.checkBoxEIC}
                  onChange={isChecked =>
                    checkBoxHandler(isChecked, 'checkBoxEIC')
                  }
                  labelTestId="checkBoxEIC-label"
                  labelText={sanitizer(
                    i18n.t('completesummary.tnc.eic.text', {
                      interpolation: { escapeValue: false },
                    }),
                    { ADD_ATTR: ['target'] }
                  )}
                  hasError={summaryFieldsErrors.checkBoxEIC}
                  hasDialog={true}
                  dialogTitle={i18n.t('completesummary.tnc.eic.header')}
                  dialogTestId="summary-eic-dialog"
                  dialogContents={[
                    {
                      id: 'eic-text',
                      body: eicContent,
                      variant: 'subtitle1',
                      element: 'label',
                    },
                  ]}
                />
              </AgilityGrid>
            )}

            {/* Requires Smart Meter checkbox  */}
            {selectedPlans[PRODUCT.ELECTRICITY]?.requiresSmartMeterUpgrade && (
              <AgilityGrid item xs={12}>
                <CustomCheckbox
                  testId="checkBoxRequiresSmartMeterUpgrade"
                  value={summaryFields.checkBoxRequiresSmartMeterUpgrade}
                  onChange={isChecked =>
                    checkBoxHandler(
                      isChecked,
                      'checkBoxRequiresSmartMeterUpgrade'
                    )
                  }
                  labelTestId="checkBoxRequiresSmartMeterUpgrade-label"
                  labelText={sanitizer(
                    i18n.t(
                      'completesummary.tnc.requiresSmartMeterUpgrade.label',
                      {
                        companyName: process.env.REACT_APP_RETAILER_NAME,
                        interpolation: { escapeValue: false },
                      }
                    )
                  )}
                  hasError={
                    summaryFieldsErrors.checkBoxRequiresSmartMeterUpgrade
                  }
                  hasDialog={true}
                  dialogTitle={i18n.t(
                    'completesummary.tnc.requiresSmartMeterUpgrade.header',
                    {
                      companyName: process.env.REACT_APP_RETAILER_NAME,
                      interpolation: { escapeValue: false },
                    }
                  )}
                  dialogTestId="summary-requiresSmartMeterUpgrade-dialog"
                  dialogContents={[
                    {
                      id: 'requiresSmartMeterUpgrade-text',
                      body: i18n.t(
                        'completesummary.tnc.requiresSmartMeterUpgrade.body',
                        {
                          companyName: process.env.REACT_APP_RETAILER_NAME,
                          interpolation: { escapeValue: false },
                          offer: selectedPlans[PRODUCT.ELECTRICITY].name,
                        }
                      ),
                      variant: 'subtitle1',
                      element: 'label',
                    },
                  ]}
                />
              </AgilityGrid>
            )}

            {/* Has Interest On Internet  - No Dialog */}
            {customConfig.hasInterestOnInternetCheckbox && (
              <AgilityGrid id="hasInterestOnInternet" item xs={12}>
                <CustomCheckbox
                  testId="checkBoxHasInterestOnInternet"
                  value={summaryFields.checkBoxHasInterestOnInternet}
                  onChange={isChecked =>
                    checkBoxHandler(isChecked, 'checkBoxHasInterestOnInternet')
                  }
                  labelTestId="checkBoxHasInterestOnInternet-label"
                  labelText={i18n.t(
                    'completeSummary.tnc.hasInterestOnInternet.text'
                  )}
                />
              </AgilityGrid>
            )}

            <div className="steps-footer full-width">
              <AgilityButton
                color="primary"
                fullWidth
                data-test-id="backButton"
                onClick={() => onNavigate('back')}
                label={
                  signupAPIResponse.expiredZappyUrlOn
                    ? i18n.t('signup.button.edit')
                    : i18n.t('signup.button.back')
                }
              />
              <AgilityButton
                fullWidth
                buttonClasses="brand-button"
                variant="contained"
                color="primary"
                type="primary"
                // loading={isSendingEmail}
                label={i18n.t('completesummary.createaccount.button')}
                data-test-id="nextButton"
                onClick={createAccount}
              />
            </div>
          </form>

          {/* <PinVerificationDialog
            data-test-id="pin-code-dialog"
            open={showPinVerification}
            onDismiss={() => setShowPinVerification(false)}
            validatePinCodes={props.validatePinCodes}
            applicationId={signupAPIResponse.applicationId}
            onSubmit={createAccount}
            requestValidationEmail={props.requestValidationEmail}
          /> */}
        </AgilityCard>
      )}
    </React.Fragment>
  );
};

CompleteSummary.propTypes = {
  selectedPlans: object,
  productList: array,
  stateList: array,
  idTypeList: array,
  saveProgressTrigger: number,
  isActive: bool,
  onNavigate: func,
  signupDataLoading: bool,
  paymentFrequencyList: array,
  concessionCardList: array,
  showInfo: bool,
  isEmbeddedNetwork: bool,
  id: string,
  className: string,
  primarySiteIdentifier: object,
  getCreditCardDetails: func,
  putSignupDetails: func,
  sendCustomerLog: func,
  customConfig: object,
};
CompleteSummary.defaultProps = {
  selectedPlans: {},
  productList: [],
  stateList: [],
  idTypeList: [],
  signupAPIResponse: {},
  saveProgressTrigger: 0,
  isActive: false,
  signupDataLoading: false,
  paymentFrequencyList: [],
  concessionCardList: [],
  showInfo: true,
  isEmbeddedNetwork: false,
  id: '',
  className: '',
  primarySiteIdentifier: {},
  customConfig: {},
};
export default CompleteSummary;
