import React from 'react';
import i18n from 'i18next';
import { Element } from 'react-scroll';

import {
  AgilityRadioGroup,
  AgilityTypography,
  AgilityGrid,
} from 'Src/AgilityComponents';

const YourDetailHearABoutUs = ({
  preferHearingList,
  handleChange,
  errors,
  fields,
  isActive,
}) => {
  const hasError = React.useCallback(
    field => errors && errors.hasOwnProperty(field) && errors[field] !== '',
    [errors]
  );

  return (
    <AgilityGrid container data-test-id="hear-about-us-option">
      <AgilityGrid item xs={12} sm={12}>
        <div className="question-wrapper">
          <AgilityTypography variant="body2" component="p">
            {i18n.t('yourDetail.header.hearAboutUs')}
          </AgilityTypography>
          <Element name="hearAboutUs">
            <AgilityRadioGroup
              options={preferHearingList || []}
              onChange={event => {
                handleChange(event.target.value, 'hearAboutUs');
              }}
              data-test-id="hearAboutUs"
              value={fields.hearAboutUs || ''}
              disabled={!isActive}
              row
              isError={hasError}
              helperText={hasError ? errors.hearAboutUs : ''}
            />
          </Element>
        </div>
      </AgilityGrid>
    </AgilityGrid>
  );
};

export default YourDetailHearABoutUs;
