/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React from 'react';
import { useTheme } from '@material-ui/core/styles';

const SliderArrow = props => {
  const { className, style, onClick } = props;
  const theme = useTheme();
  return (
    <button
      className={className}
      style={{ ...style }}
      onClick={onClick}
      css={css`
        :before {
          color: ${theme.palette.primary.main} !important;
        }
      `}
    ></button>
  );
};

export default SliderArrow;
