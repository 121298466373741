import React from 'react';
import { Link } from 'react-router-dom';
import { AgilityButton, AgilityTypography } from 'Src/AgilityComponents';
import { Helmet } from 'react-helmet';

const NotFound = props => {
  return (
    <div className="page-container page-not-found">
      <Helmet>
        <title>{`404 Not Found | ${process.env.REACT_APP_RETAILER_NAME}`}</title>
      </Helmet>
      <AgilityTypography variant="h2" component="h2" align="center">
        404: The page you are looking for isn’t here
      </AgilityTypography>
      <AgilityTypography variant="h6" component="h6" align="center">
        You either tried some shady route or you came here by mistake. Whichever
        it is, try using the navigation
      </AgilityTypography>
      <Link to="/" className="mt-3">
        <AgilityButton
          variant="outlined"
          color="primary"
          size="large"
          label=" Back to Home"
        />
      </Link>
    </div>
  );
};

export default NotFound;
