/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { useState } from 'react';
import { object, bool, number } from 'prop-types';
import i18n from 'i18next';
import './basic-details.scss';
import {
  AgilityTypography,
  AgilityButton,
  AgilityGrid,
} from 'Src/AgilityComponents';
import Link from '@material-ui/core/Link';
import PlanInfoDialog from 'App/modules/onBoarding/step1/PlanInfoDialog';
import { useTheme } from '@material-ui/core/styles';
import {
  referencePriceName,
  referenceDirection,
  getReferFromCookie,
} from 'App/utils/helper';
import greenpowerIcon from 'Images/greenpower-icon.png';
import {
  showOfferTilesMonthlyEstimate,
  showRefPriceMonthlyComparisonStriked,
} from 'App/customConfig';
import { isNullOrEmpty } from 'App/utils/validationHelper';
import dompurify from 'isomorphic-dompurify';
import PromoMessage from 'App/pages/ComparePlans/Components/ProductPlans/PromoMessage';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import merge from 'nodemon/lib/utils/merge';

const sanitizer = dompurify.sanitize;

const BasicPriceDetails = props => {
  const theme = useTheme();
  const [showDetails, setShowDetails] = useState(false);
  const togglePopup = val => {
    setShowDetails(val);
  };
  const {
    planDetails,
    productDetails,
    isEmbeddedNetwork,
    isPopup,
    showDesc,
    showLogo,
    displayProduct,
    productList,
    selectedGreenpower,
    inSidebar,
    inPlanDetailsCard,
    inCompleteSummary,
  } = props;
  const productOfferingEstimates = planDetails.productOfferingEstimate || {};
  const {
    isBestDeal = false,
    unitOfMeasure = '',
    estimatedCost = '',
    referenceCost = '',
    referenceUsage = '',
    period = '',
    estimatedCostPercentage = null,
  } = planDetails.productOfferingEstimate ?? {};

  const offerTileDescription = desc => {
    if (isNullOrEmpty(desc) || showOfferTilesMonthlyEstimate) {
      return desc;
    }
    const sentences = desc.split('.');
    if (
      (sentences.length > 1 && sentences[0].includes('the reference price')) ||
      sentences[0].includes('Victorian Default Offer')
    ) {
      sentences.shift();
    }

    return sentences.join('.').trim();
  };

  const referencePriceMessage = (state, epfsLink) => {
    // When a postcode is entered, the site's state is not set.
    // Fall back to using the epfsLink to determine the state.
    if (!state && epfsLink) {
      state = epfsLink.includes('vefs') ? 'VIC' : 'NSW'; // NSW is not VIC @see referencePriceName logic
    }

    return `${referenceDirection(estimatedCostPercentage)} ${referencePriceName(
      state
    )}`;
  };

  const estimatedCostPercentageMessage = estimatedCostPercentage => {
    if (estimatedCostPercentage === 0) {
      return i18n.t('offering.title.sameMessage');
    }

    if (estimatedCostPercentage > 0) {
      return estimatedCostPercentage + i18n.t('offering.title.moreThanMessage');
    }

    return (
      Math.abs(estimatedCostPercentage) +
      i18n.t('offering.title.lessThanMessage')
    );
  };

  const isCovau = process.env.REACT_APP_BRAND_CODE === 'COVAU';

  return (
    <div
      className={`basic-details ${
        planDetails.promocode ? 'has-promocode' : ''
      }`}
      css={theme.custom.BasicDetails.BasicDetailsCss}
    >
      <div
        className={`basic-details__summary-top`}
        css={theme.custom.BasicDetails.BasicDetailsTopCss}
      >
        {showLogo && (
          <div
            className="image-container text-center"
            data-test-id="plan-image"
          >
            <img
              src={
                planDetails.imageReference
                  ? planDetails.imageReference
                  : theme.images.product
              }
              alt="Logo"
              width="100px"
            />
          </div>
        )}
        <AgilityTypography
          variant="h5"
          className={`basic-details__summary-subheading ${
            !inSidebar ? 'text-center' : ''
          }`}
          component="h5"
          css={css`
            color: ${theme.palette.grey[500]};
            text-transform: uppercase;
            ${!inSidebar ? 'margin-top: 15px;' : ''}
          `}
        >
          {productDetails.display}
        </AgilityTypography>
        <AgilityTypography
          data-test-id="plan-name-id"
          variant="h3"
          component="h3"
          className={`basic-details__summary-heading ${
            !inSidebar ? 'text-center' : ''
          }`}
          css={css`
            ${!inSidebar ? 'padding: 14px 0;' : 'padding: 4px 0;'}
          `}
        >
          {planDetails.name}
        </AgilityTypography>
        <AgilityTypography
          variant="h6"
          className={`basic-details__summary-text ${
            !inSidebar ? 'text-center' : ''
          }`}
          component="h6"
        >
          {!planDetails.summary && inPlanDetailsCard
            ? '\u00A0'
            : planDetails.summary}
        </AgilityTypography>
      </div>
      {estimatedCost && (
        <div
          className={`basic-details__summary-bottom`}
          css={theme.custom.BasicDetails.BasicDetailsBottomCss}
        >
          <hr
            color="#DDD"
            css={css`
              ${!inSidebar ? ' margin: 25px 0;' : ' margin: 15px 0'}
            `}
          />
          {!(
            process.env.REACT_APP_BRAND_CODE === 'FIRST' &&
            productDetails.code === 'GAS'
          ) && (
            <React.Fragment>
              {estimatedCostPercentage !== null && (
                <React.Fragment>
                  <AgilityTypography
                    data-test-id="plan-price-id"
                    variant="h2"
                    component="h2"
                    containerClass="price-details"
                    css={css`
                      color: ${theme.palette.primary.main};
                    `}
                  >
                    {estimatedCostPercentageMessage(estimatedCostPercentage)}
                  </AgilityTypography>

                  <AgilityTypography
                    variant="h5"
                    component="h5"
                    containerClass="price-details-rps"
                    css={css`
                      color: ${theme.palette.grey['500']};
                      margin-top: -8px;
                      margin-bottom: 20px;
                    `}
                  >
                    {referencePriceMessage(
                      props.site?.State || props.site?.state,
                      props.planDetails.epfsLink
                    )}
                  </AgilityTypography>
                </React.Fragment>
              )}
              {showDesc && (
                <AgilityTypography
                  data-test-id="plan-desc-id"
                  variant="subtitle2"
                  component="p"
                  containerClass="price-details-desc"
                  css={css`
                    margin-top: 10px;
                    font-size: 13px;
                    line-height: 1.3;
                  `}
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(
                      offerTileDescription(planDetails.descriptionHtml)
                    ),
                  }}
                />
              )}
            </React.Fragment>
          )}
          {!isPopup && (
            <React.Fragment>
              <div
                className="flex price-details-estimated-cost"
                css={css`
                  align-items: baseline;
                  // margin-bottom: 8px;
                `}
              >
                {showRefPriceMonthlyComparisonStriked &&
                  productDetails.code !== 'GAS' &&
                  Math.round(estimatedCost) < Math.round(referenceCost) && (
                    <AgilityTypography
                      data-test-id="plan-price-id"
                      variant="h4"
                      component="strike"
                      containerClass="price-details mt-1 estimated-cost-strike"
                      css={css`
                        color: ${theme.palette.primary.main};
                        margin-right: 8px;
                      `}
                    >
                      {unitOfMeasure}
                      {Math.round(referenceCost)}
                    </AgilityTypography>
                  )}
                <AgilityTypography
                  data-test-id="plan-price-id"
                  variant="h3"
                  component="h3"
                  containerClass="price-details mt-1 estimated-cost-figure"
                  css={css`
                    color: ${theme.palette.primary.main};
                    padding: 0 8px 0 0;
                  `}
                >
                  {unitOfMeasure}
                  {Math.round(estimatedCost)}
                </AgilityTypography>
                <AgilityTypography
                  data-test-id="plan-price-id"
                  variant="body2"
                  component="p"
                  containerClass="price-details mt-1 estimated-cost-terms-gst"
                  css={css`
                    color: ${theme.palette.primary.grey};
                  `}
                >
                  {i18n.t('compareplan.plan.price.monthly')}
                  {i18n.t('compareplan.plan.gst')}
                </AgilityTypography>
              </div>
              <AgilityTypography
                variant="subtitle2"
                component="p"
                containerClass="estimated-cost-terms"
                css={css`
                  color: ${theme.palette.grey['600']};
                `}
              >
                {i18n.t('compareplan.plan.compare', {
                  estimatePeriod: 'Monthly cost',
                  usagePeriod: isCovau ? 'annual' : 'average',
                  usage: isCovau
                    ? referenceUsage
                    : (referenceUsage / 365).toFixed(1),
                  unit: planDetails.rates[0].rates[0].unit,
                  unitPeriod: isCovau ? 'year' : 'day',
                })}
              </AgilityTypography>
            </React.Fragment>
          )}
        </div>
      )}
      {planDetails.promocode !== null && (
        <PromoMessage
          fuel={productDetails.display}
          name={planDetails.promocode.name}
          description={planDetails.promocode.description}
          serviceListPosition={props.serviceListPosition}
        />
      )}
      {(inSidebar || inCompleteSummary) && getReferFromCookie() === 'RAF' && (
        <React.Fragment>
          <Alert
            className="promo-message"
            severity="info"
            color="success"
            variant="filled"
            icon={false}
            css={merge(theme.custom.PlanDetailsCard.PromoMessageCss, {
              'div[name="complete-summary"] &': {
                '@media screen and (min-width: 600px)': {
                  display: 'none',
                },
              },
            })}
          >
            <Typography
              variant="h3"
              component="h4"
              css={theme.custom.PlanDetailsCard.PromoMessageTextCss}
            >
              {i18n.t('signUpCompletion.referFriend.applied')}
              <FontAwesomeIcon
                icon={['fas', 'check-circle']}
                css={css`
                  margin-left: 6px;
                `}
              />
            </Typography>

            <Typography variant="h6" component="h6">
              <AgilityTypography
                variant="body2"
                component="p"
                align="center"
                css={theme.custom.ReferDialog.TermsCss}
                containerClass="promo-message__terms"
                dangerouslySetInnerHTML={{
                  __html: sanitizer(
                    i18n.t('signupCompletion.refer.termsShortText', {
                      link: i18n.t('signupCompletion.refer.termsLink'),
                      interpolation: { escapeValue: false },
                    })
                  ),
                }}
              />
            </Typography>
          </Alert>
        </React.Fragment>
      )}

      {selectedGreenpower && (
        <AgilityTypography variant="body2" component="p">
          <img src={greenpowerIcon} alt={`Greenpower`} height={30} />{' '}
          {`${parseInt(selectedGreenpower.percentage_green)}% Greenpower`}
        </AgilityTypography>
      )}

      {!inPlanDetailsCard && !isPopup && !inSidebar && (
        <AgilityGrid
          container
          justifyContent="center"
          className="mt-2"
          direction="row"
          alignItems="center"
          spacing={2}
        >
          <AgilityGrid item>
            <AgilityButton
              data-test-id="terms_link"
              type="mx-auto"
              variant="contained"
              color="primary"
              size="medium"
              label={i18n.t('compareplan.plandetails.text', {
                plan: planDetails.name,
              })}
              onClick={() => togglePopup(true)}
            />
          </AgilityGrid>
        </AgilityGrid>
      )}
      {!isPopup && inSidebar && (
        <Link
          data-test-id="terms_link"
          color="primary"
          className="detail-link text-left"
          css={css`
            cursor: pointer;
          `}
          onClick={() => togglePopup(true)}
        >
          {i18n.t('compareplan.plandetails.text', {
            plan: planDetails.name,
          })}
        </Link>
      )}

      <PlanInfoDialog
        data-test-id="plan-info-id"
        open={showDetails}
        content={planDetails}
        productList={productList}
        onDismiss={() => togglePopup(false)}
        isEmbeddedNetwork={isEmbeddedNetwork}
        site={props.site}
        isPopup={true}
      />
    </div>
  );
};

BasicPriceDetails.propTypes = {
  planDetails: object,
  showDesc: bool,
  showLogo: bool,
  isPopup: bool,
  selectedPlans: object,
  displayProduct: bool,
  productDetails: object,
  isEmbeddedNetwork: bool,
  inSidebar: bool,
  inPlanDetailsCard: bool,
  inCompleteSummary: bool,
  site: object,
  serviceListPosition: number,
};

BasicPriceDetails.defaultProps = {
  showDesc: true,
  showLogo: false,
  isPopup: false,
  selectedPlans: {},
  displayProduct: false,
  productDetails: {},
  isEmbeddedNetwork: false,
  inSidebar: false,
  inPlanDetailsCard: false,
  inCompleteSummary: false,
  site: {},
  serviceListPosition: 0,
};
export default BasicPriceDetails;
